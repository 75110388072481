import { ILeadsPagination } from "../interfaces/interfaces";
import { ArrowIcon } from "./icons/arrow";
import styles from "./pagination.module.scss";

const Pagination: React.FC<ILeadsPagination> = ({
  handlePageChange,
  currentPage,
  totalPages,
}: ILeadsPagination) => {
  return (
    <div className={styles.wrapper}>
      <button
        className={styles.previous}
        disabled={currentPage === 1}
        onClick={() => handlePageChange(currentPage - 1)}
      >
        <ArrowIcon />
      </button>
      <span>
        page {currentPage} of {totalPages}
      </span>
      <button
        className={styles.next}
        disabled={currentPage === totalPages}
        onClick={() => handlePageChange(currentPage + 1)}
      >
        <ArrowIcon />
      </button>
    </div>
  );
};

export default Pagination;
