import Modal from "src/_elements/modal/modal";
import styles from "./addSubmissionsModal.module.scss";
import FormInput from "src/_elements/formInput/formInput";
import { createPlanAdditionalPurchaseApi } from "src/_utils/api/api";
import { useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { observeAdditionalPurchaseAddedAC } from "src/store/actions";
import { useDispatch, useSelector } from "react-redux";

const AddSubmissionsModal: React.FC<any> = ({
  isShowAddSubmissionsModal,
  handleCloseModal,
  selectedPlanData,
}: any) => {
  const dispatch = useDispatch();

  const observeAdditionalPurchaseAdded = useSelector(
    (state: any) => state.observeAdditionalPurchaseAdded
  );

  const [name, setName] = useState("");
  const [submissionsCount, setSubmissionsCount] = useState("");
  const [price, setPrice] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmitAdditionalPurchase = () => {
    setIsLoading(true);
    const payload = {
      planId: selectedPlanData.id,
      name: name,
      type: "SUBMISSION",
      count: Number(submissionsCount),
      price: Number(price),
    };
    createPlanAdditionalPurchaseApi(payload).then(() => {
      setName("");
      setSubmissionsCount("");
      setPrice("");
      setIsLoading(false);
      handleCloseModal();
      dispatch(
        observeAdditionalPurchaseAddedAC(!observeAdditionalPurchaseAdded)
      );
    });
  };

  const closeModal = () => {
    setName("");
    setSubmissionsCount("");
    setPrice("");
    handleCloseModal();
  };

  return (
    <Modal isShow={isShowAddSubmissionsModal} onClose={closeModal}>
      <div className={styles.wrapper}>
        <p className={styles.title}>Add new additional submissions for plan</p>
        <div className={styles.inputsWrap}>
          <div>
            <p>{"Title (for users):"}</p>
            <FormInput
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Title"
            />
          </div>
          <div>
            <p>Submissions count:</p>
            <FormInput
              value={submissionsCount}
              onChange={(e) =>
                setSubmissionsCount(e.target.value.replace(/\D/g, ""))
              }
              placeholder="Count"
            />
          </div>
          <div>
            <p>Price:</p>
            <FormInput
              value={price}
              onChange={(e) => setPrice(e.target.value.replace(/\D/g, ""))}
              placeholder="Price"
            />
          </div>
        </div>
        <div className={styles.submitBtnWrap}>
          <button
            className={styles.submitBtn}
            onClick={handleSubmitAdditionalPurchase}
          >
            {!isLoading ? (
              "Submit"
            ) : (
              <RotatingLines
                visible={true}
                width="30"
                strokeWidth="5"
                animationDuration="0.75"
                strokeColor="white"
              />
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AddSubmissionsModal;
