import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ROUTES from "src/_utils/routes/routes";
import { setGoogleAuthErrorAC } from "src/store/actions";

const GoogleLogin = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");

    if (token && token !== "ERROR_THIS_EMAIL_ALREADY_IN_USE") {
      localStorage.setItem("token", token);
      navigate(ROUTES.HOME);
    } else {
      dispatch(setGoogleAuthErrorAC(true));
      navigate(ROUTES.SIGNUP);
    }
  }, [location]);

  return <div></div>;
};

export default GoogleLogin;
