import FormBuilder from "src/pages/formBuilder/formBuilder";
import styles from "./editForm.module.scss";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getFormApi } from "src/_utils/api/api";

const EditForm = () => {
  const { id } = useParams();

  const [editFormsData, setEditFormsData] = useState(null);

  useEffect(() => {
    getFormApi(id).then((res) => {
      setEditFormsData(res.data.data);
    });
  }, []);

  return (
    <div className={styles.outerWrapper}>
      <FormBuilder editFormsData={editFormsData} />
    </div>
  );
};

export default EditForm;
