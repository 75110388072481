import React, { useEffect, useState } from "react";
import styles from "./timePicker.module.scss";

const TimePicker: React.FC<any> = ({ handleChange }) => {
  const [hours, setHours] = useState(12);
  const [minutes, setMinutes] = useState(0);
  const [ampm, setAmpm] = useState("AM");

  const handleHourChange = (event: any) => {
    setHours(event.target.value);
  };

  const handleMinuteChange = (event: any) => {
    setMinutes(event.target.value);
  };

  const handleAmpmChange = (event: any) => {
    setAmpm(event.target.value);
  };

  useEffect(() => {
    handleChange &&
      handleChange(
        `${hours}:${minutes < 10 ? `0${minutes}` : minutes} ${ampm}`
      );
  }, [hours, minutes, ampm]);

  return (
    <div className={styles.outerWrapper}>
      <div className={styles.wrapper}>
        <select
          className={styles.hours}
          value={hours}
          onChange={handleHourChange}
        >
          {[...Array(12)].map((_, index) => (
            <option key={index} value={index + 1}>
              {index + 1}
            </option>
          ))}
        </select>

        <select
          className={styles.minutes}
          value={minutes}
          onChange={handleMinuteChange}
        >
          {[
            0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
            19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35,
            36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52,
            53, 54, 55, 56, 57, 58, 59,
          ].map((minute) => (
            <option key={minute} value={minute}>
              {minute < 10 ? `0${minute}` : minute}
            </option>
          ))}
        </select>

        <select
          className={styles.ampm}
          value={ampm}
          onChange={handleAmpmChange}
        >
          <option value="AM">AM</option>
          <option value="PM">PM</option>
        </select>
      </div>
    </div>
  );
};

export default TimePicker;
