import React, { useState } from "react";
import styles from "./questionElement.module.scss";
import { IAddElementButton } from "src/_elements/interfaces/interfaces";
import AddAnswerElementPopup from "../addAnswerElementPopup/addAnswerElementPopup";
import { useDispatch, useSelector } from "react-redux";
import { addAnswerTypeToQuestionAC } from "src/store/actions";
import { FORM_BUILDER_ANSWER_TYPES } from "src/_utils/formBuilderAnswerTypes";

const QuestionElement: React.FC<IAddElementButton> = ({
  children,
  handleModalOpen,
  el,
  index,
  onClick,
}: IAddElementButton) => {
  const dispatch = useDispatch();

  const questionsList = useSelector((state: any) => state.questionsList);

  const [showPopup, setShowPopup] = useState(false);

  const handleAddElement = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleSelectElementType = (type: string) => {
    if (
      (type === FORM_BUILDER_ANSWER_TYPES.answerOptions ||
        type === FORM_BUILDER_ANSWER_TYPES.answerOptionsAndTextArea) &&
      el &&
      index !== undefined &&
      handleModalOpen
    ) {
      handleModalOpen(el, index);
    }
    setShowPopup(false);

    dispatch(addAnswerTypeToQuestionAC(index, type));
  };

  const answerTypesList =
    index !== undefined ? questionsList[index].answerType : [];

  return (
    <>
      <div className={styles.outerWrapper}>
        <div onClick={onClick} className={styles.wrapper}>
          <div className={styles.content}>
            <p>{children}</p>
            <p className={styles.answerTypeLabel}>
              {answerTypesList ?? "textArea"}
            </p>
          </div>
        </div>
        <button onClick={handleAddElement}>+</button>
      </div>
      {showPopup && (
        <AddAnswerElementPopup
          handleClosePopup={handleClosePopup}
          handleSelectElementType={handleSelectElementType}
        />
      )}
    </>
  );
};

export default QuestionElement;
