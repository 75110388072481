import styles from "./leadsHeader.module.scss";

const LeadsHeader = () => {
  return (
    <div className={styles.wrapper}>
      <p>Date submitted</p>
      <p>User name</p>
      <p className={styles.questionsLabel}>Questions & Answers</p>
    </div>
  );
};

export default LeadsHeader;
