import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./datePickerComponent.module.scss";

const DatePickerComponent: React.FC<any> = ({ value, handleChange }: any) => {
  return (
    <div className={styles.datePickerWrapper}>
      <DatePicker
        selected={value}
        onChange={handleChange}
        dateFormat="MM/dd/yyyy"
        placeholderText="MM/DD/YYYY"
        className={styles.datePicker}
        calendarClassName={styles.calendarStyle}
        wrapperClassName="calendarStyle"
      />
    </div>
  );
};

export default DatePickerComponent;
