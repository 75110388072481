import styles from "./leadItem.module.scss";

const LeadItem: React.FC<any> = ({
  formattedDate,
  username,
  questionsAndAnswers,
  formData
}: any) => {

  const answers: string[][] = [];

  const data: { [key: string]: string } = { ...questionsAndAnswers.data };

  formData.questions.forEach((q: { question: string }) => {
    const item = data[q.question];
    if (item) {
      answers.push([q.question, item]);
      delete data[q.question];
    }
  });

  const questionsAndAnswersList = [...answers, ...Object.entries(data).map(item => item)].map(
    ([question, answer]) => (
      <div key={question} className={styles.questionAnswerItem}>
        <strong>{question}:</strong>
        <p>{(answer as string) === "" ? "-" : (answer as string)}</p>
      </div>
    )
  );

  return (
    <div className={styles.wrapper}>
      <p>{formattedDate}</p>
      <p className={styles.userName}>{username}</p>
      <div>{questionsAndAnswersList}</div>
    </div>
  );
};

export default LeadItem;
