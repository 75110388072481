import { useNavigate } from "react-router-dom";
import styles from "./footer.module.scss";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentCreateFormPageAC } from "src/store/actions";
import { IFooter } from "src/pages/interfaces/interfaces";

const Footer: React.FC<any> = ({ content, selectedFormTemplateId }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [pureCurrentIndex, setPureCurrentIndex] = useState(0);

  const currentIndex = useSelector((state: any) => state.currentCreateFormPage);
  const isContinueButtonDisabled = useSelector(
    (state: any) => state.isContinueButtonDisabled
  );

  const isNotFirstAndLastStep =
    currentIndex !== 0 && currentIndex !== content!.length - 1;
  const isLastStep = currentIndex !== content!.length - 1;
  const continueButtonFinalClass = `${styles.continueButton} ${
    currentIndex === 0 ? styles.continueButtonMargin : ""
  }`;
  const disableContinueButton = isContinueButtonDisabled
    ? styles.continueButtonDisabled
    : "";

  useEffect(() => {
    if (selectedFormTemplateId) {
      navigate(
        `#${
          content![currentIndex].id
        }/?form-template=${selectedFormTemplateId}`
      );
    } else {
      navigate(`#${content![currentIndex].id}`);
    }
  }, [currentIndex, navigate, selectedFormTemplateId]);

  const handleNext = () => {
    setPureCurrentIndex((prevState) => ++prevState);
    if (pureCurrentIndex < content!.length - 1) {
      dispatch(setCurrentCreateFormPageAC(pureCurrentIndex + 1));
    }
  };

  const handlePrevious = () => {
    setPureCurrentIndex((prevState) => --prevState);
    if (pureCurrentIndex > 0) {
      dispatch(setCurrentCreateFormPageAC(pureCurrentIndex - 1));
    }
  };

  return (
    <div className={styles.outerWrapper}>
      <div className={styles.wrapper}>
        {isNotFirstAndLastStep && (
          <button className={styles.backButton} onClick={handlePrevious}>
            Back
          </button>
        )}
        {isLastStep && (
          <button
            className={`${continueButtonFinalClass} ${disableContinueButton}`}
            onClick={handleNext}
            disabled={isContinueButtonDisabled}
          >
            Continue
          </button>
        )}
      </div>
    </div>
  );
};

export default Footer;
