import FormBuilder from "src/pages/formBuilder/formBuilder";
import styles from "./createFormTemplate.module.scss";

const CreateFormTemplate = () => {
  return (
    <div className={styles.outerWrapper}>
      <FormBuilder createFormTemplateFlow />
    </div>
  );
};

export default CreateFormTemplate;
