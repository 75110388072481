import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import videoAnimation from "src/assets/howItWorks/videoAnimation.mp4";
import {
  setIsShowCalendlyModalAC,
  setIsShowPricingModalAC,
  setIsShowRegisterModalAC,
} from "src/store/actions";
import Benefits from "../homePage/benefits/benefits";
import Footer from "../homePage/footer/footer";
import GetUp from "../homePage/getUp/getUp";
import Header from "../homePage/header/header";
import HowToMakeYourQuiz from "../homePage/howToMakeYourQuiz/howToMakeYourQuiz";
import Integrates from "../homePage/integrates/integrates";
import OurCustomers from "../homePage/ourCustomers/ourCustomers";
import styles from "./howItWorks.module.scss";

function HowItWorks() {
  const dispatch = useDispatch();

  const token = localStorage.getItem("token");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.outerWrapper}>
      <Helmet>
        <title>LeadQuiz | How it works</title>
      </Helmet>
      <Header />
      <div className={styles.videoSection}>
        <div className={styles.video}>
          <video width="100%" height="100%" loop autoPlay muted>
            <source src={videoAnimation} type="video/mp4" />
          </video>
        </div>
        <div className={styles.getStartedButton}>
          <button
            onClick={
              token
                ? () => dispatch(setIsShowPricingModalAC(true))
                : () => dispatch(setIsShowRegisterModalAC(true))
            }
          >
            Get started today
          </button>
        </div>
        <button className={styles.demoButton} onClick={() => dispatch(setIsShowCalendlyModalAC({ show: true, url: process.env.REACT_APP_CALENDLY_DEMO_URL! }))}>Schedule a demo</button>
      </div>
      <div className={styles.wrapper}>
        <Integrates />
        <Benefits />
        <HowToMakeYourQuiz />
        <OurCustomers />
        <div className={styles.getUpWrapper}>
          <GetUp />
        </div>
        <div className={styles.footerWrapper}>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default HowItWorks;
