import { useDispatch } from "react-redux";
import styles from "./partner.module.scss";
import {
  setIsShowPricingModalAC,
  setIsShowRegisterModalAC,
} from "src/store/actions";

const Partner = () => {
  const dispatch = useDispatch();

  const token = localStorage.getItem("token");

  return (
    <div className={styles.wrapper}>
      <h3>Partner with LeadQuiz Today</h3>
      <div className={styles.divider} />
      <p className={styles.subtitle}>
        Elevate your agency’s service offerings and deliver top-tier lead
        generation strategies for your clients. With LeadQuiz, you have the
        tools you need to help businesses grow and succeed. Sign up today to
        explore how our platform can transform your lead generation process.
      </p>
      <button
        onClick={
          token
            ? () => dispatch(setIsShowPricingModalAC(true))
            : () => dispatch(setIsShowRegisterModalAC(true))
        }
      >
        Try it for yourself
      </button>
    </div>
  );
};

export default Partner;
