import { useEffect, useState } from "react";
import { apiRoutes, getDefaultPaymentMethodApi, getPaymentMethodsApi, postPaymentMethodApi, setDefaultPaymentMethodApi } from "src/_utils/api/api";
import styles from "./paymentMethod.module.scss";
import { loadStripe, StripeCardElement } from "@stripe/stripe-js";
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import PaymentMethodItem from "./paymentMethodItem/paymentMethodItem";

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY as string);
const token = localStorage.getItem("token");

const PaymentMethodForm = (handleGetPaymentMethods: any) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState<string | undefined | null>(null);
  const [loading, setLoading] = useState(false);

  const handleAddPaymentMethod = async (event: any) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { data } = await fetch(`${process.env.REACT_APP_API_URL}${apiRoutes.stripeCreateSetupIntent}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({})
    }).then(res => res.json()).catch(err => console.log(err))

    const { setupIntent, error } = await stripe.confirmCardSetup(data.clientSecret, {
      payment_method: {
        card: cardElement as StripeCardElement,
      },
    });
    if (error) {
      setErrorMessage(error.message);
      setLoading(false);
    } else {
      setLoading(false);
      handleGetPaymentMethods.handleGetPaymentMethods();
    }
  };

  return (
    <form onSubmit={handleAddPaymentMethod}>
      <CardElement />
      {errorMessage && <div>{errorMessage}</div>}
      <button className={styles.addPaymentMethodBtn} type="submit" disabled={!stripe || loading}>
        {loading ? "Processing..." : "Add payment method"}
      </button>
    </form>
  );
};

const PaymentMethod = () => {
  const [clientSecret, setClientSecret] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState<any>(null)
  const [defaultPaymentMethodId, setDefaultPaymentMethodId] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const handleGetPaymentMethods = () => {
    getPaymentMethodsApi().then(res => {
      setPaymentMethods(res.data.data.data)
    }).catch(err => console.log(err))
  }

  useEffect(() => {
    setIsLoading(true)
    const fetchSetupIntent = async () => {
      try {
        //@ts-ignore
        const response = await postPaymentMethodApi("/stripe/create-setup-intent", {});
        setClientSecret(response.data.data.clientSecret);
      } catch (error) {
        console.error("Error fetching client secret:", error);
      }
    };

    fetchSetupIntent();

    handleGetPaymentMethods();

    getDefaultPaymentMethodApi().then(res => {
      setDefaultPaymentMethodId(res.data.data.id)
      setIsLoading(false)
    }).catch(() => {
      setIsLoading(false)
    })
  }, []);

  const handleSetDefaultPaymentMethod = (paymentMethodId: string) => {
    setIsLoading(true)
    const payload = { paymentMethodId };
    setDefaultPaymentMethodApi(payload).then(() => {
      getDefaultPaymentMethodApi().then((res) => {
        setDefaultPaymentMethodId(res.data.data.id);
        setIsLoading(false)
      });
    });
  };

  const paymentMethodsList = paymentMethods && 
    paymentMethods.map((el: any, index: number) => (
      <PaymentMethodItem 
        brand={el.card.brand} 
        cardNumber={el.card.last4}
        expMonth={el.card.exp_month}
        expYear={el.card.exp_year}
        id={el.id}
        isDefault={defaultPaymentMethodId === el.id}
        onSetDefault={() => handleSetDefaultPaymentMethod(el.id)}
        isLoading={isLoading}
        key={index}
      />
    ))

  return (
    <div className={styles.paymentMethod}>
      <p className={styles.title}>Change payment method</p>
      {clientSecret ? (
        <Elements stripe={stripePromise}>
          <PaymentMethodForm handleGetPaymentMethods={handleGetPaymentMethods} />
        </Elements>
      ) : (
        <div>You don't have a subscription</div>
      )}
      <div className={styles.paymentMethodsWrap}>
        {paymentMethodsList}
      </div>
    </div>
  );
};

export default PaymentMethod;
