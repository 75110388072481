import React from "react";
import styles from "./registerModal.module.scss";
import Modal from "src/_elements/modal/modal";
import SignUpComponent from "src/pages/auth/signup/signupComponent/signupComponent";
import { setIsShowRegisterModalAC } from "src/store/actions";
import { useDispatch, useSelector } from "react-redux";

const RegisterModal = () => {
  const dispatch = useDispatch();

  const isShowRegisterModal = useSelector(
    (state: any) => state.isShowRegisterModal
  );

  return (
    <Modal
      className={styles.wrapper}
      isShow={isShowRegisterModal}
      onClose={() => dispatch(setIsShowRegisterModalAC(false))}
    >
      <div className={styles.content}>
        <SignUpComponent />
      </div>
    </Modal>
  );
};

export default RegisterModal;
