import styles from "./paymentType.module.scss";

const PaymentType: React.FC<any> = ({
  selectedPaymentType,
  setselectedPaymentType,
}: any) => {
  const MONTH_PAYMENT_TYPE = "month";
  const YEAR_PAYMENT_TYPE = "year";

  return (
    <div className={styles.wrapper}>
      <div className={styles.paymentTypeWrapper}>
        <button
          onClick={() => setselectedPaymentType(MONTH_PAYMENT_TYPE)}
          className={`${styles.switchButton} ${
            selectedPaymentType === MONTH_PAYMENT_TYPE
              ? styles.selectedSwitchButton
              : ""
          }`}
        >
          Pay Monthly
        </button>
        <button
          onClick={() => setselectedPaymentType(YEAR_PAYMENT_TYPE)}
          className={`${styles.switchButton} ${
            selectedPaymentType === YEAR_PAYMENT_TYPE
              ? styles.selectedSwitchButton
              : ""
          }`}
        >
          Pay Yearly
        </button>
      </div>
      <p className={styles.footer}>2 months free!* Billed Annually*</p>
    </div>
  );
};

export default PaymentType;
