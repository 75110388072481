import Modal from "src/_elements/modal/modal";
import styles from "./actionModal.module.scss";
import { IActionModal } from "../interfaces/interfaces";

const ActionModal: React.FC<IActionModal> = ({
  title,
  subtitle,
  isShowModal,
  handleCloseModal,
  handleAction,
  actionButtonText = "Ok",
  actionButtonColor = "#df1b41",
}: IActionModal) => {
  return (
    <Modal className={styles.outerWrapper} isShow={isShowModal}>
      <div className={styles.wrapper}>
        <p className={styles.title}>{title}</p>
        <p className={styles.subtitle}>{subtitle}</p>
        <div className={styles.buttonsWrap}>
          <button
            className={styles.cancelButton}
            onClick={handleCloseModal}
          >
            Cancel
          </button>
          <button
            style={{ backgroundColor: actionButtonColor }}
            className={styles.actionButton}
            onClick={handleAction}
          >
            {actionButtonText}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ActionModal;
