import { jwtDecode } from "jwt-decode";

export function getUserRole(token) {
  try {
    if (token) {
      const decodedToken = jwtDecode(token);

      const userRoles = decodedToken.roles;

      return userRoles;
    }
  } catch (error) {
    console.error("Invalid token:", error);
    return null;
  }
}
