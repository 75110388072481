import PaymentPlan from "src/pages/homePage/payments/paymentPlan/paymentPlan";
import styles from "./currentPlan.module.scss";

const CurrentPlan: React.FC<any> = ({
  isCurrentPlanAvailable,
  currentPlanName,
  planPrice,
  maxDomains,
  maxSubmissionsPerMonth,
  currentSubscriptionSchedule,
  isCurrentPlanChanged,
  setIsCurrentPlanChanged,
  totalAdditionalSubmissionsCount,
  additionalPurchases,
  isNeedToContactSales,
  isNeedUpdatePlan,
  periodLabel,
  currentPlanStartDate,
  currentPlanStartDateNoScheduule,
  currentPlanEndDate,
  currentPlanEndDateNoScheduule,
  isPlanPermanent,
}: any) => {
  return (
    <div className={styles.wrapper}>
      <h2>Your current plan:</h2>
      {isCurrentPlanAvailable ? (
        <PaymentPlan
          title={currentPlanName}
          price={planPrice}
          maxDomains={maxDomains}
          maxSubmissionsPerMonth={maxSubmissionsPerMonth}
          isBought
          endBehavior={currentSubscriptionSchedule?.end_behavior}
          isPlanChanged={currentSubscriptionSchedule?.phases[1]}
          isCurrentPlanChanged={isCurrentPlanChanged}
          setIsCurrentPlanChanged={setIsCurrentPlanChanged}
          totalAdditionalSubmissionsCount={totalAdditionalSubmissionsCount}
          additionalPurchases={additionalPurchases}
          isNeedToContactSales={isNeedToContactSales}
          isNeedUpdatePlan={isNeedUpdatePlan}
          isCurrentPlanAvailable={isCurrentPlanAvailable}
          periodLabel={periodLabel}
          isPlanPermanent={isPlanPermanent}
        />
      ) : (
        <p>You don't have a subscription</p>
      )}
      {isCurrentPlanAvailable && !isPlanPermanent && (
        <p className={styles.period}>
          Current plan period:{" "}
          <span>
            {currentPlanStartDate !== "Invalid Date"
              ? currentPlanStartDate
              : currentPlanStartDateNoScheduule.split("T")[0]}
          </span>{" "}
          to{" "}
          <span>
            {currentPlanEndDate !== "Invalid Date"
              ? currentPlanEndDate
              : currentPlanEndDateNoScheduule.split("T")[0]}
          </span>
        </p>
      )}
    </div>
  );
};

export default CurrentPlan;
