import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AnswerItemButtonWithImage from "src/pages/formBuilder/buttonsWithImageEdit/answerItemButtonWithImage";
import { setCurrentFormPageAC, setUserAnswersAC } from "src/store/actions";
import styles from "./questionPage.module.scss";
import { FORM_BUILDER_ANSWER_TYPES } from "src/_utils/formBuilderAnswerTypes";
import TextArea from "src/_elements/textArea/textArea";
import DatePickerComponent from "src/_elements/datePickerComponent/datePickerComponent";
import { format } from "date-fns";
import TimePicker from "src/_elements/timePicker/timePicker";
import FileUploader from "src/_elements/fileUploader/fileUploader";

const QuestionPage: React.FC<any> = ({
  element,
  isVisible,
  answerIndex,
  selectedAnswer,
  setSelectedAnswer,
  facebookPixelId,
  isShowEndingPage,
  googleAdsId,
  conversionLabel,
}: any) => {
  const dispatch = useDispatch();

  const currentIndex = useSelector((state: any) => state.currentFormPage);
  const questionsList = useSelector((state: any) => state.questionsList);
  const allAnswers = useSelector((state: any) => state.allAnswers);
  const isBackPressed = useSelector((state: any) => state.isBackPressed);

  const containerRef = useRef<HTMLDivElement>(null);

  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [selectedAnswers, setSelectedAnswers] = useState<any[]>([]);
  const [multiselectTextArea, setMultiselectTextArea] = useState("");
  const [fileName, setFileName] = useState("Click to upload your file");
  const [isFileSizeError, setIsFileSizeError] = useState(false);

  useEffect(() => {
    setSelectedAnswers([]);
    setFileName("Click to upload your file");
  }, [isBackPressed]);

  useEffect(() => {
    if (!isShowEndingPage) {
      if (facebookPixelId && !document.getElementById("facebook-pixel")) {
        const script = document.createElement("script");
        script.id = "facebook-pixel";
        script.innerHTML = `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${facebookPixelId}');
        fbq('track', 'PageView');
      `;
        document.head.appendChild(script);

        const noscript = document.createElement("noscript");
        noscript.id = "facebook-pixel-noscript";
        noscript.innerHTML = `<img height="1" width="1" style="display:none"
           src="https://www.facebook.com/tr?id=${facebookPixelId}&ev=PageView&noscript=1" />`;
        document.body.appendChild(noscript);

        return () => {
          document.getElementById("facebook-pixel")?.remove();
          document.getElementById("facebook-pixel-noscript")?.remove();
        };
      }

      const scriptId = `google-ads-${googleAdsId}`;

      if (googleAdsId && !document.getElementById(scriptId)) {
        const script = document.createElement("script");
        script.id = scriptId;
        script.innerHTML = `
          (function(w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
            var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'dataLayer', '${googleAdsId}');
        `;
        document.head.appendChild(script);

        const noscript = document.createElement("noscript");
        noscript.id = scriptId + "-noscript";
        noscript.innerHTML = `<img height="1" width="1" style="display:none"
          src="https://www.googleadservices.com/pagead/conversion/${googleAdsId}/?label=${conversionLabel}&guid=ON&script=0" />`;
        document.body.appendChild(noscript);
      }
    } else {
      const scriptId = `facebook-pixel-${facebookPixelId}`;

      if (facebookPixelId && !document.getElementById(scriptId)) {
        const script = document.createElement("script");
        script.id = scriptId;
        script.innerHTML = `
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${facebookPixelId}');
          fbq('track', 'PageView');
          fbq('track', 'Lead');
        `;
        document.head.appendChild(script);

        const noscript = document.createElement("noscript");
        noscript.id = scriptId + "-noscript";
        noscript.innerHTML = `<img height="1" width="1" style="display:none"
          src="https://www.facebook.com/tr?id=${facebookPixelId}&ev=PageView&noscript=1" />`;
        document.body.appendChild(noscript);
      }

      const googleScriptId = `google-ads-${googleAdsId}`;

      if (googleAdsId && !document.getElementById(googleScriptId)) {
        const script = document.createElement("script");
        script.id = googleScriptId;
        script.innerHTML = `
          (function(w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
            var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'dataLayer', '${googleAdsId}');
        `;
        document.head.appendChild(script);

        const noscript = document.createElement("noscript");
        noscript.id = googleScriptId + "-noscript";
        noscript.innerHTML = `<img height="1" width="1" style="display:none"
          src="https://www.googleadservices.com/pagead/conversion/${googleAdsId}/?label=${conversionLabel}&guid=ON&script=0" />`;
        document.body.appendChild(noscript);
      }
    }
  }, [facebookPixelId, isShowEndingPage, googleAdsId, conversionLabel]);

  useEffect(() => {
    if (
      questionsList.questions[currentIndex]?.conditionalAnswerOptions
        ?.value[0] &&
      !questionsList.questions[currentIndex]?.conditionalAnswerOptions
        ?.isHide &&
      !questionsList.questions[
        currentIndex
      ]?.conditionalAnswerOptions?.value.some((option: any) =>
        allAnswers.includes(option.answerOptionId)
      )
    ) {
      dispatch(
        setCurrentFormPageAC(
          isBackPressed ? currentIndex - 1 : currentIndex + 1
        )
      );
    } else if (
      questionsList.questions[currentIndex]?.conditionalAnswerOptions
        ?.value[0] &&
      questionsList.questions[currentIndex]?.conditionalAnswerOptions?.isHide &&
      questionsList.questions[
        currentIndex
      ]?.conditionalAnswerOptions?.value.some((option: any) =>
        allAnswers.includes(option.answerOptionId)
      )
    ) {
      dispatch(
        setCurrentFormPageAC(
          isBackPressed ? currentIndex - 1 : currentIndex + 1
        )
      );
    }
  }, [questionsList, currentIndex, allAnswers, isBackPressed]);

  if (!isVisible) {
    return null;
  }

  const handleSelectDate = (date: string) => {
    setSelectedDate(date);
  };

  const handleSelectTime = (value: string) => {
    setSelectedTime(value);
  };

  if (selectedDate !== "" && selectedTime !== "") {
    dispatch(
      setUserAnswersAC(
        {
          question: element.question,
          answer: `${selectedDate}, ${selectedTime}`,
        },
        answerIndex
      )
    );
  }

  const handleMultiSelectAnswer = (e: any) => {
    setMultiselectTextArea(e.target.value);

    if (!element.isMultipleSelect) {
      setSelectedAnswer(null);

      dispatch(
        setUserAnswersAC(
          { question: element.question, answer: e.target.value },
          answerIndex
        )
      );
    } else {
      const selectedAnswersList = selectedAnswers.map((el) =>
        selectedAnswers.length > 1 ? " " + el.title : el.title
      );

      dispatch(
        setUserAnswersAC(
          {
            question: element.question,
            answer: selectedAnswersList[0]
              ? selectedAnswersList + ", " + e.target.value
              : e.target.value,
          },
          answerIndex
        )
      );
    }
  };

  const handleAnswer = (e: any, el: any) => {
    if (element.isMultipleSelect) {
      const updatedAnswers = selectedAnswers.includes(el)
        ? selectedAnswers.filter((answer) => answer !== el)
        : [...selectedAnswers, el];
      setSelectedAnswers(updatedAnswers);
      dispatch(
        setUserAnswersAC(
          {
            question: element.question,
            answer:
              updatedAnswers.map((a) => a?.title).join(", ") +
              `${
                updatedAnswers.length >= 1 && multiselectTextArea
                  ? ", " + multiselectTextArea
                  : multiselectTextArea
              }`,
          },
          answerIndex
        )
      );
    } else {
      setMultiselectTextArea("");
      setSelectedAnswer(el);
      dispatch(
        setUserAnswersAC(
          { question: element.question, answer: e.target.value },
          answerIndex
        )
      );
    }
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files![0];
    if (file) {
      const fileSizeInMB = file.size / 1024 / 1024;
      if (fileSizeInMB >= 5) {
        setFileName("");
        setIsFileSizeError(true);
      } else {
        setIsFileSizeError(false);
        setFileName(file.name);
        dispatch(
          setUserAnswersAC(
            {
              question: element.question,
              answer: file,
            },
            answerIndex
          )
        );
      }
    } else {
      setFileName("");
    }
  };

  return (
    <div className={styles.questionsWrapper}>
      <p className={styles.questionTitle}>{element.question}</p>
      <div className={styles.inputElement}>
        {(element.answerType === undefined ||
          element.answerType === FORM_BUILDER_ANSWER_TYPES.textArea) && (
          <TextArea
            onChange={handleAnswer}
            placeholder="Write your answer here"
          />
        )}
      </div>
      {element.answerType === FORM_BUILDER_ANSWER_TYPES.answerOptions && (
        <div ref={containerRef} className={styles.answerOptions}>
          {element.answerOptions &&
            element.answerOptions.map((el: any, index: number) => (
              <AnswerItemButtonWithImage
                key={index}
                question={el.title}
                icon={el.icon}
                handleOptionChange={(e: any) => handleAnswer(e, el)}
                el={el}
                selectedAnswer={
                  element.isMultipleSelect ? selectedAnswers : selectedAnswer
                }
                isMultipleSelect={element.isMultipleSelect}
              />
            ))}
        </div>
      )}
      {element.answerType ===
        FORM_BUILDER_ANSWER_TYPES.answerOptionsAndTextArea && (
        <div>
          <div ref={containerRef} className={styles.answerOptions}>
            {element.answerOptions &&
              element.answerOptions.map((el: any, index: number) => (
                <AnswerItemButtonWithImage
                  key={index}
                  question={el.title}
                  icon={el.icon}
                  handleOptionChange={(e: any) => handleAnswer(e, el)}
                  el={el}
                  selectedAnswer={
                    element.isMultipleSelect ? selectedAnswers : selectedAnswer
                  }
                  isMultipleSelect={element.isMultipleSelect}
                />
              ))}
          </div>
          <div className={styles.writeYourAnswerDivider}>
            <p>Or write your own answer</p>
          </div>
          <TextArea
            onChange={handleMultiSelectAnswer}
            placeholder="Write your answer here"
            value={multiselectTextArea}
          />
        </div>
      )}
      {element.answerType === FORM_BUILDER_ANSWER_TYPES.dateAndTimePicker && (
        <div className={styles.inputElement}>
          <div className={styles.dateAndTimePickerWrapper}>
            <div className={styles.datePickerWrap}>
              <DatePickerComponent
                value={selectedDate}
                handleChange={(date: string) =>
                  handleSelectDate(format(date, "MM/dd/yyyy"))
                }
              />
            </div>
            <TimePicker handleChange={handleSelectTime} />
          </div>
        </div>
      )}
      {element.answerType === FORM_BUILDER_ANSWER_TYPES.attachFile && (
        <div className={styles.fileUplaoderWrap}>
          {isFileSizeError && (
            <p className={styles.errorMessage}>
              {"Maximum file size should be < 5Mb"}
            </p>
          )}
          <FileUploader
            handleFileSelect={handleFileSelect}
            fileName={fileName}
          />
        </div>
      )}
    </div>
  );
};

export default QuestionPage;
