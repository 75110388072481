import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ROUTES from "src/_utils/routes/routes";

const PaymentSuccess = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(ROUTES.SETTINGS);
  }, []);

  return <div></div>;
};

export default PaymentSuccess;
