import React from "react";
import styles from "./addAnswerElementPopup.module.scss";
import crossIcon from "./../../../../../assets/cross.svg";
import { FORM_BUILDER_ANSWER_TYPES } from "src/_utils/formBuilderAnswerTypes";

const AddAnswerElementPopup: React.FC<any> = ({
  handleClosePopup,
  handleSelectElementType,
}: any) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <p className={styles.title}>Select element type</p>
        <img src={crossIcon} alt="close" onClick={handleClosePopup} />
      </div>
      <ul>
        <li
          onClick={() =>
            handleSelectElementType(FORM_BUILDER_ANSWER_TYPES.textArea)
          }
        >
          text area
        </li>
        <li
          onClick={() =>
            handleSelectElementType(FORM_BUILDER_ANSWER_TYPES.answerOptions)
          }
        >
          answer options
        </li>
        <li
          onClick={() =>
            handleSelectElementType(
              FORM_BUILDER_ANSWER_TYPES.answerOptionsAndTextArea
            )
          }
        >
          answer options + text area
        </li>
        <li
          onClick={() =>
            handleSelectElementType(FORM_BUILDER_ANSWER_TYPES.dateAndTimePicker)
          }
        >
          date & time picker
        </li>
        <li
          onClick={() =>
            handleSelectElementType(FORM_BUILDER_ANSWER_TYPES.attachFile)
          }
        >
          attach file
        </li>
      </ul>
    </div>
  );
};

export default AddAnswerElementPopup;
