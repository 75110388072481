import React, { useEffect, useState } from "react";
import styles from "./iconsListModal.module.scss";
import Modal from "src/_elements/modal/modal";
import { RotatingLines } from "react-loader-spinner";
import Pagination from "../pagination/pagination";
import FileUploader from "src/_elements/fileUploader/fileUploader";
import { getIconsApi, getIconsStyleApi } from "src/_utils/api/api";
import {
  IGetIconsPayload,
  IIcon,
  IIconsListModal,
  IIconStyles,
} from "src/_elements/interfaces/interfaces";

const IconsListModal: React.FC<IIconsListModal> = ({
  isShowIconsList,
  setIsShowIconsList,
  handleSelect,
  setCustomIcon,
  setCustomIconPreview,
}: IIconsListModal) => {
  const [totalIconsCount, setTotalIconsCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [icons, setIcons] = useState([]);
  const [iconsStyles, setIconsStyles] = useState([]);
  const [selectedIconsStyle, setSelectedIconsStyle] = useState("outline");
  const [searchValue, setSearchValue] = useState("home");
  const [isLoading, setIsLoading] = useState(false);
  const [uploadedIconName, setUploadedIconName] = useState<string | null>(null);
  const [isFileSizeError, setIsFileSizeError] = useState(false);
  const [isFileTypeError, setIsFileTypeError] = useState(false);

  useEffect(() => {
    const initialPayload: IGetIconsPayload = {
      query: "home",
      count: 21,
      offset: 0,
      style: selectedIconsStyle,
    };

    getIconsList(initialPayload);

    getIconsStyleApi()
      .then((res) => {
        setIconsStyles(res.data.data.styles);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const payload: IGetIconsPayload = {
      query: searchValue,
      count: 21,
      offset: (currentPage - 1) * 21,
      style: selectedIconsStyle,
    };

    getIconsList(payload);
  }, [currentPage]);

  const handleIconsSearch = () => {
    setCurrentPage(1);

    const payload: IGetIconsPayload = {
      query: searchValue,
      count: 21,
      offset: 0,
      style: selectedIconsStyle,
    };

    getIconsList(payload);
  };

  const onPageChange = (page: number | string) => {
    setCurrentPage(page as number);
  };

  const getIconsList = (payload: IGetIconsPayload) => {
    setIsLoading(true);

    getIconsApi(payload)
      .then((res) => {
        setIsLoading(false);
        setTotalIconsCount(res.data.data.total_count);
        setIcons(res.data.data.icons);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const handleIconsStyleSelect = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedIconsStyle(event.target.value);

    setCurrentPage(1);

    const payload: IGetIconsPayload = {
      query: searchValue,
      count: 21,
      offset: 0,
      style: event.target.value,
    };

    getIconsList(payload);
  };

  const handleImageSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files![0];
    if (file) {
      const validImageTypes = ["image/jpeg", "image/png"];
      if (!validImageTypes.includes(file.type)) {
        setIsFileTypeError(true);
        setUploadedIconName(null);
        setCustomIcon(null);
        setCustomIconPreview("");
        return;
      }

      const fileSizeInMB = file.size / 1024 / 1024;
      if (fileSizeInMB >= 3) {
        setIsFileSizeError(true);
        setUploadedIconName(null);
        setCustomIcon(null);
        setCustomIconPreview("");
      } else {
        setIsFileTypeError(false);
        setIsFileSizeError(false);
        setUploadedIconName(file.name);
        setCustomIcon(file);
        const imageUrl = URL.createObjectURL(file);
        setCustomIconPreview(imageUrl);
      }
    } else {
      setUploadedIconName(null);
    }
  };

  return (
    <Modal
      className={styles.wrapper}
      isShow={isShowIconsList}
      onClose={() => setIsShowIconsList(false)}
    >
      <div className={styles.searchWrapper}>
        <input
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          type="text"
        />
        <button onClick={handleIconsSearch}>Search</button>
        <select
          className={styles.iconsStyleSelect}
          value={selectedIconsStyle}
          onChange={handleIconsStyleSelect}
        >
          <option value="" disabled>
            Icons style
          </option>
          {iconsStyles.map((item: IIconStyles, index: number) => (
            <option key={index} value={item.identifier}>
              {item.identifier}
            </option>
          ))}
        </select>
      </div>
      <div className={styles.customLogoUploaderWrapper}>
        {isFileSizeError && (
          <p className={styles.errorMessage}>
            {"Maximum file size should be < 3Mb"}
          </p>
        )}
        {isFileTypeError && (
          <p className={styles.errorMessage}>
            {"Available formats: .jpeg, .png"}
          </p>
        )}
        <FileUploader
          isShowIcon={false}
          handleFileSelect={handleImageSelect}
          fileName={uploadedIconName}
        />
      </div>
      <div className={styles.iconsWrapper}>
        <div className={styles.iconsSelectWrapper}>
          {!isLoading ? (
            icons.map((icon: IIcon, index: number) => (
              <div
                className={styles.iconsSelect}
                key={index}
                onClick={() => handleSelect(icon)}
              >
                <img
                  src={
                    icon.raster_sizes[
                      icon.raster_sizes[4] ? 4 : icon.raster_sizes.length - 1
                    ].formats[0].preview_url
                  }
                  alt="icon"
                />
              </div>
            ))
          ) : (
            <div className={styles.iconsLoadingWrapper}>
              <RotatingLines
                visible={true}
                width="30"
                strokeWidth="5"
                animationDuration="0.75"
                strokeColor="black"
              />
            </div>
          )}
        </div>
      </div>
      <Pagination
        totalCount={totalIconsCount}
        currentPage={currentPage}
        onPageChange={onPageChange}
        pageSize={21}
        siblingCount={1}
      />
    </Modal>
  );
};

export default IconsListModal;
