import { useDispatch } from "react-redux";
import styles from "./top.module.scss";
import devices from "src/assets/homePage/devices.png";
import {
  setIsShowPricingModalAC,
  setIsShowRegisterModalAC,
} from "src/store/actions";

const Top: React.FC<any> = ({ tradeName }: any) => {
  const dispatch = useDispatch();

  const token = localStorage.getItem("token");

  return (
    <div className={styles.outerWrapper}>
      <div className={styles.wrapper}>
        <div className={styles.textContent}>
          <h1 className={styles.title}>
            LeadQuiz is the perfect tool for <span>{tradeName.toLowerCase()}</span> businesses
            looking to streamline their lead collection process.
          </h1>
          <p className={styles.subtitle}>
            Capture valuable customer insights with ease and boost engagement
            through customizable, user-friendly forms.
          </p>
          <button
            onClick={
              token
                ? () => dispatch(setIsShowPricingModalAC(true))
                : () => dispatch(setIsShowRegisterModalAC(true))
            }
            className={styles.btn}
          >
            Try it for yourself
          </button>
        </div>
        <div className={styles.topImgWrap}>
          <img src={devices} alt="devices" />
        </div>
      </div>
    </div>
  );
};

export default Top;
