import { useEffect, useState } from "react";
import styles from "./ourCustomers.module.scss";
import arrowIcon from "src/assets/homePage/arrow.svg";

const OurCustomers = () => {
  const initialSliderText =
    "LeadQuiz has enabled our Painting & Coatings business to distinguish itself in the competitive advertising market. We can gather all the customized information we require even before prospects become leads. It also simplifies the process by directly populating leads into our CRM.";
  const initialSliderAuthor = "- Tommy Nuygen, Owner of PaintPros";

  const [currentSliderStep, setCurrentSliderStep] = useState(1);
  const [currentSliderText, setCurrentSliderText] = useState(initialSliderText);
  const [currentSliderAuthor, setCurrentSliderAuthor] =
    useState(initialSliderAuthor);

  useEffect(() => {
    switch (currentSliderStep) {
      case 1:
        setCurrentSliderText(initialSliderText);
        setCurrentSliderAuthor(initialSliderAuthor);
        break;
      case 2:
        setCurrentSliderText(
          "This form has help us increase our over all cost per lead, and ultimately our conversion rate."
        );
        setCurrentSliderAuthor("- Adam Reed, Owner of Lynx Concrete Coatings.");
        break;
      case 3:
        setCurrentSliderText(
          "We couldn't be more happy with our LeadQuiz. We utilize it across all of our marketing platforms. Including Facebook Ads, Google Ads,  Direct Mail, and even have a QR code on our business cards."
        );
        setCurrentSliderAuthor("- Kevin Jones, Owner of HVAC NWA.");
        break;
    }

    if (currentSliderStep < 1) {
      setCurrentSliderStep(3);
    } else if (currentSliderStep > 3) {
      setCurrentSliderStep(1);
    }
  }, [currentSliderStep]);

  return (
    <div className={styles.wrapper}>
      <h5 className={styles.title}>
        Our customers seem to think we know what we're doing.
      </h5>
      <div className={styles.contentWrapper}>
        <div className={styles.sliderWrapper}>
          <p>{currentSliderText}</p>
          <p>{currentSliderAuthor}</p>
        </div>
        <div className={styles.navigationButtons}>
          <button
            onClick={() => setCurrentSliderStep((prevCount) => prevCount - 1)}
          >
            <img src={arrowIcon} alt="arrow top" />
          </button>
          <button
            onClick={() => setCurrentSliderStep((prevCount) => prevCount + 1)}
          >
            <img src={arrowIcon} alt="arrow bottom" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default OurCustomers;
