import { ILeadsSelectForm } from "../interfaces/interfaces";
import styles from "./selectForm.module.scss";

const SelectForm: React.FC<ILeadsSelectForm> = ({
  selectedForm,
  handleFormChange,
  formsArray,
}: ILeadsSelectForm) => {
  return (
    <select
      value={selectedForm}
      onChange={handleFormChange}
      className={styles.wrapper}
    >
      {formsArray.map((option: any, index: number) => (
        <option key={index} value={option.form.id}>
          {option.form.name}
        </option>
      ))}
    </select>
  );
};

export default SelectForm;
