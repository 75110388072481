import Modal from "src/_elements/modal/modal";
import styles from "./businessProfileModal.module.scss";
import Input from "src/_elements/input/input";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import { E164Number } from "libphonenumber-js";
import { RotatingLines } from "react-loader-spinner";

const BusinessProfileModal: React.FC<any> = ({
  title,
  isShow,
  handleClose,
  handleSave,
  errorMessage,
  isLoading,
  isSaved,
  businessProfileData,
  handleAxiosAction,
}: any) => {
  const [businessName, setBusinessName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState<E164Number>();
  const [privacyPolicyLink, setPrivacyPolicyLink] = useState("");

  const initiateEditBusinessProfileState = () => {
    setBusinessName(businessProfileData.businessName);
    setEmail(businessProfileData.email);
    setPhone(businessProfileData.phone);
    setPrivacyPolicyLink(businessProfileData.privacyPolicyLink);
  };

  const handleCloseModal = () => {
    if (businessProfileData) {
      initiateEditBusinessProfileState();
    } else {
      setBusinessName("");
      setEmail("");
      setPhone("" as E164Number);
      setPrivacyPolicyLink("");
    }

    handleClose();
  };

  useEffect(() => {
    isSaved && handleCloseModal();
  }, [isSaved]);

  useEffect(() => {
    if (businessProfileData) {
      initiateEditBusinessProfileState();
    }
  }, [businessProfileData]);

  return (
    <Modal isShow={isShow} onClose={handleCloseModal}>
      <div className={styles.content}>
        <p className={styles.title}>{title}</p>
        <div className={styles.inputsWrap}>
          <Input
            value={businessName}
            onChange={(e) => setBusinessName(e.target.value)}
            placeholder="Business name"
            label="Business name:"
          />
          <Input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Business email"
            label="Business email:"
            autoComplete="email"
          />
          <PhoneInput
            placeholder="Business phone"
            value={phone}
            onChange={(value) => setPhone(value)}
            className={styles.phoneInput}
            defaultCountry="US"
          />
          <Input
            value={privacyPolicyLink}
            onChange={(e) => setPrivacyPolicyLink(e.target.value)}
            placeholder="Privacy policy link"
            label="Privacy policy link:"
          />
        </div>
        {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
        <div className={styles.btnWrap}>
          <button
            onClick={() =>
              handleSave(
                {
                  businessName: businessName,
                  email: email,
                  phone: phone,
                  privacyPolicyLink: privacyPolicyLink,
                },
                handleAxiosAction
              )
            }
          >
            {!isLoading ? (
              "Save"
            ) : (
              <RotatingLines
                visible={true}
                width="30"
                strokeWidth="5"
                animationDuration="0.75"
                strokeColor="white"
              />
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default BusinessProfileModal;
