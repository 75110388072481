import { RotatingLines } from "react-loader-spinner";
import styles from "./paymentMethodItem.module.scss";
import { useEffect, useState } from "react";

const PaymentMethodItem: React.FC<any> = ({
  brand,
  cardNumber,
  expMonth,
  expYear,
  isDefault,
  onSetDefault,
  isLoading,
}: any) => {
  const [isDefaultPaymentMethod, setIsDefaultPaymentMethod] =
    useState(isDefault);

  useEffect(() => {
    setIsDefaultPaymentMethod(isDefault);
  }, [isDefault]);

  return (
    <div
      className={`${styles.wrapper} ${
        isDefaultPaymentMethod ? styles.default : ""
      }`}
    >
      <p>
        Credit Card: {brand} ending {cardNumber}
      </p>
      <p>
        expiring: {expMonth}/{expYear}
      </p>
      {!isDefaultPaymentMethod && (
        <>
          <button onClick={onSetDefault} className={styles.btn}>
            Set as default
          </button>
          {isLoading && (
            <RotatingLines
              visible={true}
              width="20"
              strokeWidth="5"
              animationDuration="0.75"
              strokeColor="black"
            />
          )}
        </>
      )}
      {isDefaultPaymentMethod && (
        <div className={styles.defaultLabel}>Default</div>
      )}
    </div>
  );
};

export default PaymentMethodItem;
