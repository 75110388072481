import styles from "./privacy-policy.module.scss";

function PrivacyPolicy() {

  return (
    <div className={styles.outerWrapper}>
      <div className={styles.wrapper}>
        <p className={styles.privacyPolicy}>LeadQuiz - Privacy Policy</p>
        {/* <p>&nbsp;</p> */}
        <p className={styles.lastUpdated}><strong>Last updated: 9/17/24</strong></p>
        <p>At LeadQuiz, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your data when you use our website and services.</p>
        <h3><strong>1. Information We Collect</strong></h3>
        <p>We collect information to provide better services and enhance your experience on our platform. The information we collect includes:</p>
        <ul>
        <li>
        <p><strong>Personal Information:</strong>&nbsp;Such as your name, email address, phone number, company name, and other contact details when you sign up for our services or contact us.</p>
        </li>
        <li>
        <p><strong>Business Information:</strong>&nbsp;Any information related to your business (such as job details, contact information) that you provide in the course of using our services.</p>
        </li>
        <li>
        <p><strong>Usage Data:</strong>&nbsp;Information about how you access and interact with our website, including your IP address, browser type, operating system, pages visited, and other similar data.</p>
        </li>
        <li>
        <p><strong>Cookies and Tracking Technologies:</strong>&nbsp;We use cookies and similar tracking technologies to monitor user activity and improve the functionality of our website.</p>
        </li>
        </ul>
        <h3><strong>2. How We Use Your Information</strong></h3>
        <p>We may use the information collected for various purposes, including:</p>
        <ul>
        <li>
        <p>To provide and maintain our services.</p>
        </li>
        <li>
        <p>To process your requests, including responding to inquiries and sending updates about our services.</p>
        </li>
        <li>
        <p>To personalize and improve your experience on the website.</p>
        </li>
        <li>
        <p>To communicate with you about promotions, updates, or other information we think may interest you.</p>
        </li>
        <li>
        <p>To monitor and analyze usage trends to improve the functionality of our platform.</p>
        </li>
        <li>
        <p>To comply with legal obligations and prevent fraud or abuse of our services.</p>
        </li>
        </ul>
        <h3><strong>3. How We Share Your Information</strong></h3>
        <p>We do not sell or rent your personal information to third parties. However, we may share your information in the following ways:</p>
        <ul>
        <li>
        <p><strong>Service Providers:</strong>&nbsp;We may share your data with third-party vendors who provide services on our behalf, such as hosting, email delivery, or customer support.</p>
        </li>
        <li>
        <p><strong>Business Transfers:</strong>&nbsp;In the event of a merger, acquisition, or asset sale, your data may be transferred to a new entity.</p>
        </li>
        <li>
        <p><strong>Legal Compliance:</strong>&nbsp;We may disclose your information if required by law or to protect the rights, property, or safety of LeadQuiz, our customers, or others.</p>
        </li>
        </ul>
        <h3><strong>4. Data Security</strong></h3>
        <p>We take reasonable measures to protect your data from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.</p>
        <h3><strong>5. Your Data Rights</strong></h3>
        <p>Depending on your jurisdiction, you may have the following rights regarding your personal information:</p>
        <ul>
        <li>
        <p><strong>Access and Update:</strong>&nbsp;You may access, update, or delete your personal information by contacting us.</p>
        </li>
        <li>
        <p><strong>Opt-Out:</strong>&nbsp;You can opt-out of receiving marketing communications from us at any time.</p>
        </li>
        <li>
        <p><strong>Data Portability and Deletion:</strong>&nbsp;You may request a copy of your data or ask us to delete your information, subject to legal and contractual restrictions.</p>
        </li>
        </ul>
        <h3><strong>6. Third-Party Links</strong></h3>
        <p>Our website may contain links to third-party sites that are not operated by us. We are not responsible for the privacy practices or content of these external sites. We encourage you to review their privacy policies.</p>
        <h3><strong>7. Children&rsquo;s Privacy</strong></h3>
        <p>Our services are not intended for individuals under the age of 13. We do not knowingly collect personal information from children. If we become aware of such data, we will take steps to delete it.</p>
        <h3><strong>8. Changes to This Privacy Policy</strong></h3>
        <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page with the updated effective date.</p>
        <h3><strong>9. Contact Us</strong></h3>
        <p>If you have any questions about this Privacy Policy, please contact us at:</p>
        <p>LeadQuiz<br />Email: <a href="mailto:Support@leadquiz.com">Support@leadquiz.com</a></p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
