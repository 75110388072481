import { Link } from "react-router-dom";
import styles from "./whoWeServeList.module.scss";
import ROUTES from "src/_utils/routes/routes";
import generalContracting from "src/assets/whoWeServe/generalContracting.png";
import painting from "src/assets/whoWeServe/painting.png";
import roofing from "src/assets/whoWeServe/roofing.png";
import solar from "src/assets/whoWeServe/solar.png";
import plumbing from "src/assets/whoWeServe/plumbing.png";
import electrical from "src/assets/whoWeServe/electrical.png";
import arrow from "src/assets/arrow.svg";

const WhoWeServeList: React.FC<any> = ({ setIsWhoWeServeListOpen }: any) => {
  const handleSelectTrade = () => {
    setIsWhoWeServeListOpen(false);
    window.scrollTo(0, 0);
  };

  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>POPULAR TRADES</p>
      <div className={styles.tradesList}>
        <Link
          to={`${ROUTES.WHO_WE_SERVE}${ROUTES.GENERAL_CONTRACTING}`}
          onClick={handleSelectTrade}
        >
          <img src={generalContracting} />
          <p>General Contracting</p>
        </Link>
        <Link
          to={`${ROUTES.WHO_WE_SERVE}${ROUTES.PAINTING}`}
          onClick={handleSelectTrade}
        >
          <img src={painting} />
          <p>Painting</p>
        </Link>
        <Link
          to={`${ROUTES.WHO_WE_SERVE}${ROUTES.ROOFING}`}
          onClick={handleSelectTrade}
        >
          <img src={roofing} />
          <p>Roofing</p>
        </Link>
        <Link
          to={`${ROUTES.WHO_WE_SERVE}${ROUTES.SOLAR}`}
          onClick={handleSelectTrade}
        >
          <img src={solar} />
          <p>Solar</p>
        </Link>
        <Link
          to={`${ROUTES.WHO_WE_SERVE}${ROUTES.PLUMBING}`}
          onClick={handleSelectTrade}
        >
          <img src={plumbing} />
          <p>Plumbing</p>
        </Link>
        <Link
          to={`${ROUTES.WHO_WE_SERVE}${ROUTES.ELECTRICAL}`}
          onClick={handleSelectTrade}
        >
          <img src={electrical} />
          <p>Electrical</p>
        </Link>
      </div>
      <Link to={ROUTES.WHO_WE_SERVE} className={styles.exploreMore}>
        Explore All Trades <img src={arrow} />
      </Link>
    </div>
  );
};

export default WhoWeServeList;
