import Downloader from "../downloader/downloader";
import Search from "../search/search";
import SelectForm from "../selectForm/selectForm";
import Calendars from "./calendars/calendars";
import styles from "./filter.module.scss";

const Filter: React.FC<any> = ({
  selectedForm,
  handleFormChange,
  formsArray,
  handleSearch,
  handleClearSearchInput,
  searchUsernameText,
  setStartDate,
  setEndDate,
  startDate,
  endDate,
  leadsListForDownload
}: any) => {
  return (
    <div className={styles.searchWrapper}>
      <SelectForm
        selectedForm={selectedForm}
        handleFormChange={handleFormChange}
        formsArray={formsArray}
      />
      <Calendars
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        startDate={startDate}
        endDate={endDate}
      />
      <Search
        handleSearch={handleSearch}
        handleClearSearchInput={handleClearSearchInput}
        value={searchUsernameText}
      />
      <Downloader leadsListForDownload={leadsListForDownload} formName={formsArray.find((form: any) => form.form.id === selectedForm)?.form?.name} />
    </div>
  );
};

export default Filter;
