import { useEffect } from "react";
import Benefits from "./benefits/benefits";
import Footer from "./footer/footer";
import GetUp from "./getUp/getUp";
import Header from "./header/header";
import styles from "./homePage.module.scss";
import HowToMakeYourQuiz from "./howToMakeYourQuiz/howToMakeYourQuiz";
import Integrates from "./integrates/integrates";
import OurCustomers from "./ourCustomers/ourCustomers";
import Payments from "./payments/payments";
import Top from "./top/top";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { setIsShowPricingModalAC } from "src/store/actions";

const HomePage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);

    const isFirstLogin = localStorage.getItem("isFirstLogin");
    isFirstLogin === "true" && dispatch(setIsShowPricingModalAC(true));
    localStorage.removeItem("isFirstLogin");
  }, []);

  return (
    <div className={styles.outerWrapper}>
      <Helmet>
        <title>LeadQuiz</title>
      </Helmet>
      <Header />
      <div className={styles.topOuterBg}>
        <div className={styles.wrapper}>
          <Top />
        </div>
      </div>
      <div className={styles.wrapper}>
        <Integrates />
        <Benefits />
        <HowToMakeYourQuiz />
        <OurCustomers />
        <div className={styles.paymentsWrap}>
          <Payments />
        </div>
        <GetUp />
        <div className={styles.footerWrapper}>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
