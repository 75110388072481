import styles from "./apiAccess.module.scss";
import copy from "./../../../assets/copy.svg";
import Input from "src/_elements/input/input";

const ApiAccess: React.FC<any> = ({
  currentUserZapierApiKey,
  handleCopyClick,
  zapierApiKeyCopyStatus,
}: any) => {
  return (
    <div className={styles.apiKeyWrapper}>
      <p className={styles.title}>API access key</p>
      <div className={styles.zapierApiKeyInputWrapper}>
        <Input value={currentUserZapierApiKey} />
        <img src={copy} alt="copy" onClick={handleCopyClick} />
        <p className={styles.copyStatus}>{zapierApiKeyCopyStatus}</p>
      </div>
    </div>
  );
};

export default ApiAccess;
