import styles from "./signup.module.scss";
import { Helmet } from "react-helmet";
import SignUpComponent from "./signupComponent/signupComponent";

const SignUp = () => {
  return (
    <div className={styles.outerWrapper}>
      <Helmet>
        <title>LeadQuiz | Sign up</title>
      </Helmet>
      <SignUpComponent />
    </div>
  );
};

export default SignUp;
