import styles from "./login.module.scss";
import { Helmet } from "react-helmet";
import LoginComponent from "./loginComponent/loginComponent";

const LogIn = () => {
  return (
    <div className={styles.outerWrapper}>
      <Helmet>
        <title>LeadQuiz | Login</title>
      </Helmet>
      <LoginComponent />
    </div>
  );
};

export default LogIn;
