import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import ActionModal from "src/_elements/actionModal/actionModal";
import {
  apiRoutes,
  cancelSubscriptionAtEndApi,
  changePlanApi,
  getSubscriptionScheduleApi,
  reactivatePlatApi,
} from "src/_utils/api/api";
import pointIcon from "src/assets/homePage/point.svg";
import {
  observeNextPlanChangedAC,
  setIsShowCalendlyModalAC,
  setIsShowRegisterModalAC,
} from "src/store/actions";
import styles from "./paymentPlan.module.scss";

const PaymentPlan: React.FC<any> = ({
  title,
  price,
  list,
  maxDomains,
  maxSubmissionsPerMonth,
  id,
  priceId,
  isBought,
  isNextPlan,
  endBehavior,
  isPlanChanged,
  isCurrentPlanChanged,
  setIsCurrentPlanChanged,
  totalAdditionalSubmissionsCount,
  additionalPurchases,
  isNeedToContactSales,
  isNeedUpdatePlan,
  isCurrentPlanAvailable,
  isYearly,
  isPersonalPlan,
  isPlanPermanent,
}: any) => {
  const [listArray, setListArray] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isChangeNextPlanLoading, setIsChangeNextPlanLoading] = useState(false);
  const [isShowUnsubscribeModal, setIsShowUnsubscribeModal] = useState(false);
  const [isShowReactivateModal, setIsShowReactivateModal] = useState(false);
  const [isShowChangePlanModal, setIsShowChangePlanModal] = useState(false);
  const [currentEndBehavior, setCurrentEndBehavior] = useState(endBehavior);
  const [currentPrice, setCurrentPrice] = useState(0);

  const dispatch = useDispatch();

  const observeNextPlanChanged = useSelector(
    (state: any) => state.observeNextPlanChanged
  );

  useEffect(() => {
    setCurrentEndBehavior(endBehavior);
  }, [endBehavior]);

  useEffect(() => {
    setIsCurrentPlanChanged && setIsCurrentPlanChanged(isPlanChanged);
  }, [isPlanChanged]);

  useEffect(() => {
    if (list) {
      setListArray(
        list &&
          list.map((el: string, index: number) => (
            <li key={index}>
              <img
                style={{ filter: `invert(${title === "Pro" ? 1 : 0})` }}
                src={pointIcon}
                alt="point"
              />
              <p>{el}</p>
            </li>
          ))
      );
    } else {
      const list = [
        `Custom Questions`,
        `${maxDomains} Custom ${maxDomains === 1 ? "Domain" : "Domains"}`,
        `${
          totalAdditionalSubmissionsCount
            ? maxSubmissionsPerMonth + totalAdditionalSubmissionsCount
            : maxSubmissionsPerMonth
        } Submissions/Month`,
      ];

      setListArray(
        list &&
          list.map((el: string, index: number) => (
            <li key={index}>
              <img
                style={{ filter: `invert(${title === "Pro" ? 1 : 0})` }}
                src={pointIcon}
                alt="point"
              />
              <p>{el}</p>
            </li>
          ))
      );
    }
  }, [maxDomains, maxSubmissionsPerMonth]);

  const handleCloseUnsubscribeModal = () => {
    setIsShowUnsubscribeModal(false);
  };

  const handleCloseReactivateModal = () => {
    setIsShowReactivateModal(false);
  };

  const handleCloseChangePlanModal = () => {
    setIsShowChangePlanModal(false);
  };

  const handleGetPlan = async () => {
    const token = localStorage.getItem("token");

    if (token) {
      const stripePromise = loadStripe(
        process.env.REACT_APP_PUBLISHABLE_KEY as string
      );

      const stripe = await stripePromise;

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}${apiRoutes.stripeCreateSession}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ planId: id, priceId }),
        }
      );

      const data = await response.json();

      const sessionId = data.data.sessionId;

      //@ts-ignore
      const { error } = await stripe?.redirectToCheckout({
        sessionId,
      });
    } else {
      dispatch(setIsShowRegisterModalAC(true));
    }
  };

  const handleGetAdditionalPurchase = async (stripePriceId: string) => {
    const token = localStorage.getItem("token");

    const stripePromise = loadStripe(
      process.env.REACT_APP_PUBLISHABLE_KEY as string
    );

    const stripe = await stripePromise;

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}${apiRoutes.stripeCreatePurchaseAdditionalSession}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          stripePriceId,
        }),
      }
    );

    const data = await response.json();

    const sessionId = data.data.sessionId;

    //@ts-ignore
    const { error } = await stripe?.redirectToCheckout({
      sessionId,
    });
  };

  const handleChangePlan = () => {
    setIsShowChangePlanModal(false);
    setIsChangeNextPlanLoading(true);
    changePlanApi(priceId)
      .then(() => {
        dispatch(observeNextPlanChangedAC(!observeNextPlanChanged));
        setIsChangeNextPlanLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const showPrice = price && price.toString().padStart(3, "0");

  const handleUnsubscribe = () => {
    setIsShowUnsubscribeModal(false);
    setIsLoading(true);
    cancelSubscriptionAtEndApi().then(() => {
      getSubscriptionScheduleApi().then((res) => {
        setCurrentEndBehavior(res.data.data[0].end_behavior);
        setIsCurrentPlanChanged(res.data.data[0].phases[1]);
        setIsLoading(false);
      });
    });
  };

  const handleReactivatePlan = () => {
    setIsShowReactivateModal(false);
    setIsLoading(true);
    reactivatePlatApi().then(() => {
      getSubscriptionScheduleApi().then((res) => {
        setCurrentEndBehavior(res.data.data[0].end_behavior);
        setIsCurrentPlanChanged(res.data.data[0].phases[1]);
        setIsLoading(false);
      });
    });
  };

  const additionalPurchasesList =
    additionalPurchases &&
    additionalPurchases.map((el: any, index: number) => (
      <button
        key={index}
        onClick={() => handleGetAdditionalPurchase(el.stripePriceId)}
      >
        {el.name}
      </button>
    ));

  useEffect(() => {
    showPrice &&
      setCurrentPrice(
        parseFloat(showPrice.slice(0, -2) + "." + showPrice.slice(-2))
      );
  }, [showPrice]);

  useEffect(() => {
    if (isYearly) {
      setCurrentPrice((prevState) => Math.ceil(prevState / 12));
    }
  }, [isYearly]);

  return (
    <>
      <div
        className={`${styles.wrapper} ${
          title !== "Pro" ? styles.lightPlan : styles.darkPlan
        }`}
      >
        <p className={styles.title}>{title}</p>
        {isPersonalPlan && (
          <p className={styles.personalPlanLabel}> (Personal offer)</p>
        )}
        {isNeedUpdatePlan && (
          <div className={styles.alert}>
            <p>
              price has changed, please re-subscribe to current or another plan
              from the next period
            </p>
          </div>
        )}
        {(price || price === 0) && !isNeedToContactSales && (
          <p>
            <span className={styles.price}>
              {price === 0 ? "$0.00" : `$${currentPrice.toFixed(2)}`}
            </span>
            /month
          </p>
        )}
        <div className={styles.buttonWrapper}>
          {!isNextPlan &&
            !isBought &&
            !isNeedToContactSales &&
            !isCurrentPlanAvailable &&
            title !== "Enterprise" && (
              <button onClick={handleGetPlan}>
                Get {title.toLowerCase()} plan
              </button>
            )}
          {title === "Enterprise" && (
            <button
              onClick={() =>
                dispatch(
                  setIsShowCalendlyModalAC({
                    show: true,
                    url: process.env.REACT_APP_CALENDLY_URL!,
                  })
                )
              }
            >
              Contact Us
            </button>
          )}
          {!isNextPlan && !price && isNeedToContactSales && (
            <button>Contact sales</button>
          )}
          {isBought &&
            !isCurrentPlanChanged &&
            !isPlanPermanent &&
            currentEndBehavior !== "cancel" && (
              <button onClick={() => setIsShowUnsubscribeModal(true)}>
                {!isLoading ? (
                  "Unsubscribe"
                ) : (
                  <RotatingLines
                    visible={true}
                    width="30"
                    strokeWidth="5"
                    animationDuration="0.75"
                    strokeColor="white"
                  />
                )}
              </button>
            )}
          {isBought &&
            (isCurrentPlanChanged || currentEndBehavior === "cancel") &&
            !isNeedUpdatePlan && (
              <button onClick={() => setIsShowReactivateModal(true)}>
                {!isLoading ? (
                  "Reactivate"
                ) : (
                  <RotatingLines
                    visible={true}
                    width="30"
                    strokeWidth="5"
                    animationDuration="0.75"
                    strokeColor="white"
                  />
                )}
              </button>
            )}
          {!isNextPlan &&
            !isBought &&
            !isNeedToContactSales &&
            isCurrentPlanAvailable && (
              <button onClick={() => setIsShowChangePlanModal(true)}>
                {!isChangeNextPlanLoading ? (
                  `Change to ${title.toLowerCase()}`
                ) : (
                  <RotatingLines
                    visible={true}
                    width="30"
                    strokeWidth="5"
                    animationDuration="0.75"
                    strokeColor="white"
                  />
                )}
              </button>
            )}
        </div>
        <ul>{listArray}</ul>
        {isBought && additionalPurchasesList[0] && (
          <>
            <p className={styles.additionalPurchasesTitle}>
              Additional purchases:{" "}
            </p>
            <div className={styles.additionalPurchasesWrapper}>
              {additionalPurchasesList}
            </div>
          </>
        )}
      </div>
      <ActionModal
        title={`Unsubscribe from plan ${title}?`}
        subtitle="This will unsubscribe you from your current plan, but it will remain
          in effect until your next payment date, after which the plan will
          cease and no money will be charged."
        isShowModal={isShowUnsubscribeModal}
        handleCloseModal={handleCloseUnsubscribeModal}
        handleAction={handleUnsubscribe}
        actionButtonText="Unsubscribe"
      />
      <ActionModal
        title={`Reactivate plan ${title}?`}
        subtitle="This will restore your plan subscription."
        isShowModal={isShowReactivateModal}
        handleCloseModal={handleCloseReactivateModal}
        handleAction={handleReactivatePlan}
        actionButtonText="Reactivate"
      />
      <ActionModal
        title={`Change plan to ${title}?`}
        subtitle="When your current plan ends, your selected plan will take effect. Are you sure you want to change your plan?"
        isShowModal={isShowChangePlanModal}
        handleCloseModal={handleCloseChangePlanModal}
        handleAction={handleChangePlan}
        actionButtonText="Change plan"
      />
    </>
  );
};

export default PaymentPlan;
