import { Link } from "react-router-dom";
import styles from "./header.module.scss";
import ROUTES from "src/_utils/routes/routes";
import companyLogo from "src/assets/homePage/companyLogo.png";

function Header() {
  return (
    <div className={styles.outerWrapper}>
      <div className={styles.wrapper}>
        <Link to={ROUTES.HOME}>
          <img width="170px" height="68px" src={companyLogo} alt="logo" />
        </Link>
      </div>
    </div>
  );
}

export default Header;
