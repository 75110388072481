import React from "react";
import styles from "./formTemplateItem.module.scss";

const FormTemplateItem: React.FC<any> = ({ title, img }: any) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <img height="50px" width="50px" src={img} alt="logo" />
        <p>{title}</p>
      </div>
    </div>
  );
};

export default FormTemplateItem;
