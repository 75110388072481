import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import {
  setIsShowCalendlyModalAC,
  setIsShowPricingModalAC,
  setIsShowRegisterModalAC,
} from "src/store/actions";
import Benefits from "../homePage/benefits/benefits";
import Footer from "../homePage/footer/footer";
import Header from "../homePage/header/header";
import Payments from "../homePage/payments/payments";
import styles from "./pricing.module.scss";

function Pricing() {
  const dispatch = useDispatch();

  const token = localStorage.getItem("token");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.outerWrapper}>
      <Helmet>
        <title>LeadQuiz | Pricing</title>
      </Helmet>
      <Header />
      <div className={styles.wrapper}>
        <div className={styles.pricingTopOuterWrapper}>
          <div className={styles.pricingTopWrapper}>
            <h1>Start generating quality leads.</h1>
            <p>
              Replace your Facebook forms, and confusing websites with an
              interactive form. Allowing your business to increase lead quality
              and convert more clients.
            </p>
            <div className={styles.getStartedButton}>
              <button
                onClick={
                  token
                    ? () => dispatch(setIsShowPricingModalAC(true))
                    : () => dispatch(setIsShowRegisterModalAC(true))
                }
              >
                Get started today
              </button>
            </div>
            <button className={styles.demoButton} onClick={() => dispatch(setIsShowCalendlyModalAC({ show: true, url: process.env.REACT_APP_CALENDLY_DEMO_URL! }))}>Schedule a demo</button>
          </div>
        </div>
        <div className={styles.paymentsWrap}>
          <Payments />
        </div>
        <Benefits />
        <div className={styles.footerWrapper}>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Pricing;
