import { ICardItem } from "../interfaces/interfaces";
import styles from "./cardItem.module.scss";

const CardItem: React.FC<ICardItem> = ({ img, text }: ICardItem) => {
  return (
    <div className={styles.cardItem}>
      <img src={img} alt="trade" />
      <p>{text}</p>
    </div>
  );
};

export default CardItem;
