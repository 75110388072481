import Modal from "src/_elements/modal/modal";
import styles from "./configPlanModal.module.scss";
import FormInput from "src/_elements/formInput/formInput";
import { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import {
  createPeronalPlansApi,
  findUserInfoByEmailApi,
  getPlansListApi,
  patchPlansListApi,
  postPlansListApi,
} from "src/_utils/api/api";
import { IPostPlansListPayload } from "src/_utils/interfaces/interfaces";
import { IConfigPlanModal } from "src/pages/interfaces/interfaces";

const ConfigPlanModal: React.FC<IConfigPlanModal> = ({
  isShowPlanEditModal,
  handleCloseModal,
  selectedPlanData,
  setPlansList,
  isPersonalPlan,
  setIsPersonalPlan,
}: IConfigPlanModal) => {
  const [isLoading, setIsLoading] = useState(false);

  const [planName, setPlanName] = useState("");
  const [maxDomains, setMaxDomains] = useState(0);
  const [pricePerMonth, setPricePerMonth] = useState(0);
  const [pricePerYear, setPricePerYear] = useState(0);
  const [maxSubmissionsPerMonth, setMaxSubmissionsPerMonth] = useState(0);
  const [isNeedToContactSales, setIsNeedToContactSales] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [isUserFoundError, setIsUserFoundError] = useState(false);

  const fillInitialData = () => {
    if (selectedPlanData) {
      const {
        name,
        maxDomains,
        stripePrices,
        maxSubmissionsPerMonth,
        needToContactSales,
        active,
      } = selectedPlanData;

      setPlanName(name);
      setMaxDomains(maxDomains);
      setPricePerMonth(stripePrices?.pricePerMonth.unit_amount);
      setPricePerYear(stripePrices?.pricePerYear.unit_amount);
      setMaxSubmissionsPerMonth(maxSubmissionsPerMonth);
      setIsNeedToContactSales(needToContactSales);
      setIsActive(active);
    } else {
      setPlanName("");
      setMaxDomains(0);
      setPricePerMonth(0);
      setPricePerYear(0);
      setMaxSubmissionsPerMonth(0);
      setIsNeedToContactSales(false);
      setIsActive(false);
    }
  };

  useEffect(() => {
    fillInitialData();
  }, [selectedPlanData]);

  const handleCloseModalButtonClick = () => {
    fillInitialData();
    handleCloseModal();
    setIsPersonalPlan(false);
    setUserEmail("");
    setIsUserFoundError(false);
  };

  const postPlan = () => {
    const payload = {
      name: planName,
      stripePrices: {
        pricePerMonth: {
          id: selectedPlanData?.stripePrices.pricePerMonth.id,
          recurring: {
            interval: "month",
          },
          unit_amount: Number(pricePerMonth),
          productId: selectedPlanData?.stripePrices.pricePerMonth.productId,
        },
        pricePerYear: {
          id: selectedPlanData?.stripePrices.pricePerYear.id,
          recurring: {
            interval: "year",
          },
          unit_amount: Number(pricePerYear),
          productId: selectedPlanData?.stripePrices.pricePerYear.productId,
        },
      },
      maxDomains: Number(maxDomains),
      maxSubmissionsPerMonth: Number(maxSubmissionsPerMonth),
      needToContactSales: isNeedToContactSales,
      active: isActive,
      details: {},
    };

    if (selectedPlanData) {
      setIsLoading(true);

      patchPlansListApi(payload, selectedPlanData.id).then(() => {
        setIsLoading(false);
        handleCloseModalButtonClick();
        getPlansListApi()
          .then((res) => {
            setPlansList(res.data.data);
          })
          .catch((err) => console.log(err));
      });
    } else if (isPersonalPlan) {
      setIsLoading(true);
      setIsUserFoundError(false);

      const payload: IPostPlansListPayload = {
        name: planName,
        pricePerMonth: Number(pricePerMonth),
        pricePerYear: Number(pricePerYear),
        maxDomains: Number(maxDomains),
        maxSubmissionsPerMonth: Number(maxSubmissionsPerMonth),
        needToContactSales: isNeedToContactSales,
        active: isActive,
        details: {},
      };

      findUserInfoByEmailApi(userEmail)
        .then((res) => {
          createPeronalPlansApi(payload, res.data.data.id)
            .then(() => {
              setIsUserFoundError(false);
              setIsLoading(false);
              handleCloseModalButtonClick();
              getPlansListApi()
                .then((res) => {
                  setPlansList(res.data.data);
                })
                .catch((err) => console.log(err));
            })
            .catch(() => {
              setIsUserFoundError(true);
              setIsLoading(false);
            });
        })
        .catch(() => {
          setIsUserFoundError(true);
          setIsLoading(false);
        });
    } else {
      setIsLoading(true);

      const payload: IPostPlansListPayload = {
        name: planName,
        pricePerMonth: Number(pricePerMonth),
        pricePerYear: Number(pricePerYear),
        maxDomains: Number(maxDomains),
        maxSubmissionsPerMonth: Number(maxSubmissionsPerMonth),
        needToContactSales: isNeedToContactSales,
        active: isActive,
        details: {},
      };

      postPlansListApi(payload).then(() => {
        setIsLoading(false);
        handleCloseModalButtonClick();
        getPlansListApi()
          .then((res) => {
            setPlansList(res.data.data);
          })
          .catch((err) => console.log(err));
      });
    }
  };

  return (
    <Modal isShow={isShowPlanEditModal} onClose={handleCloseModalButtonClick}>
      <div className={styles.planWrapper}>
        {isPersonalPlan && (
          <div className={styles.userEmail}>
            <p>User email:</p>
            <FormInput
              value={userEmail}
              onChange={(e: any) => setUserEmail(e.target.value)}
              type="text"
              placeholder="User email"
            />
          </div>
        )}
        <p>Plan name:</p>
        <FormInput
          value={planName}
          onChange={(e: any) => setPlanName(e.target.value)}
          type="text"
          placeholder="Plan name"
        />
        <div className={styles.inputsWrap}>
          <div>
            <p>Price per month:</p>
            <FormInput
              value={pricePerMonth}
              onChange={(e: any) => setPricePerMonth(e.target.value)}
              type="number"
              placeholder="Price per month"
            />
          </div>
          <div>
            <p>Price per year:</p>
            <FormInput
              value={pricePerYear}
              onChange={(e: any) => setPricePerYear(e.target.value)}
              type="number"
              placeholder="Price per year"
            />
          </div>
          <div>
            <p>Max domains:</p>
            <FormInput
              value={maxDomains}
              onChange={(e: any) => setMaxDomains(e.target.value)}
              type="number"
              placeholder="Max domains"
            />
          </div>
          <div>
            <p>Max submissions per month:</p>
            <FormInput
              value={maxSubmissionsPerMonth}
              onChange={(e: any) => setMaxSubmissionsPerMonth(e.target.value)}
              type="number"
              placeholder="Max submissions per month"
            />
          </div>
        </div>
        <div className={styles.checkboxWrapper}>
          <input
            className={styles.checkbox}
            type="checkbox"
            name="needToContactSales"
            value="Need to contact sales"
            checked={isNeedToContactSales}
            onChange={(e: any) => setIsNeedToContactSales(e.target.checked)}
          />
          <p>Need to contact sales</p>
        </div>
        <div className={styles.checkboxWrapper}>
          <input
            className={styles.checkbox}
            type="checkbox"
            name="active"
            value="Active"
            checked={isActive}
            onChange={(e: any) => setIsActive(e.target.checked)}
          />
          <p>Active</p>
        </div>
        {isUserFoundError && (
          <p className={styles.errorMessage}>User not found</p>
        )}
        <button onClick={postPlan} className={styles.saveBtn}>
          {!isLoading ? (
            "Save"
          ) : (
            <RotatingLines
              visible={true}
              width="30"
              strokeWidth="5"
              animationDuration="0.75"
              strokeColor="white"
            />
          )}
        </button>
      </div>
    </Modal>
  );
};

export default ConfigPlanModal;
