import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "./changeEmail.module.scss";
import { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import ROUTES from "src/_utils/routes/routes";
import { changeEmailApi, requestChangeEmailApi } from "src/_utils/api/api";
import { Helmet } from "react-helmet";
import companyLogo from "src/assets/homePage/companyLogo.png";

const ChangeEmail = () => {
  const [userEmail, setUserEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccessfull, setIsSuccessfull] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const params = new URLSearchParams(location.search);
  const token = params.get("token");

  useEffect(() => {
    if (token) {
      changeEmailApi({ token: token }).then(() => {
        navigate(ROUTES.LOGIN);
      });
    }
  }, [token]);

  const handleChangeEmail = () => {
    if (userEmail !== "") {
      setIsLoading(true);

      const payload = {
        newEmail: userEmail,
      };

      requestChangeEmailApi(payload)
        .then(() => {
          setIsError(false);
          setIsLoading(false);
          setIsSuccessfull(true);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setIsError(true);
          setIsLoading(false);
        });
    }
  };

  return (
    <div className={styles.outerWrapper}>
      <Helmet>
        <title>LeadQuiz | Change email</title>
      </Helmet>
      <div className={styles.wrapper}>
        <Link to={ROUTES.HOME}>
          <img
            width="170px"
            height="68px"
            src={companyLogo}
            alt="company logo"
          />
        </Link>
        <div className={styles.inputsWrapper}>
          <input
            className={isError ? styles.errorInput : ""}
            value={userEmail}
            onChange={(e) => setUserEmail(e.currentTarget.value)}
            type="email"
            autoComplete="email"
            placeholder="Email Address"
          />
          {isError && (
            <p className={styles.errorMessage}>Email is incorrect.</p>
          )}
          {isSuccessfull && (
            <p className={styles.successMessage}>
              A confirmation message has been sent to your email.
            </p>
          )}
          <button onClick={handleChangeEmail}>
            {!isLoading ? (
              "Change email"
            ) : (
              <RotatingLines
                visible={true}
                width="30"
                strokeWidth="5"
                animationDuration="0.75"
                strokeColor="white"
              />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChangeEmail;
