import Header from "../homePage/header/header";
import styles from "./adminPage.module.scss";
import { useEffect, useState } from "react";
import Plan from "./plan/plan";
import ConfigPlanModal from "./configPlanModal/configPlanModal";
import { getFormTemplatesApi, getPlansListApi } from "src/_utils/api/api";
import { IPlanData } from "../interfaces/interfaces";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import ROUTES from "src/_utils/routes/routes";
import { getUserRole } from "src/_utils/userRoles/getUserRole";
import ROLES from "src/_utils/userRoles/userRoles";
import AddSubmissionsModal from "./addSubmissionsModal/addSubmissionsModal";
import PersonalPlans from "./personalPlans/personalPlans";
import FormTemplates from "./formTemplates/formTemplates";
import Pagination from "../leads/pagination/pagination";

const AdminPage = () => {
  const token = localStorage.getItem("token");

  const pageSize = 4;

  const navigate = useNavigate();
  const userRole = getUserRole(token);

  const [plansList, setPlansList] = useState<IPlanData[] | null>(null);
  const [formTemplatesList, setFormTemplatesList] = useState(null);
  const [isShowAddSubmissionsModal, setIsShowAddSubmissionsModal] =
    useState(false);
  const [isShowPlanEditModal, setIsShowPlanEditModal] = useState(false);
  const [selectedPlanData, setSelectedPlanData] = useState<null | IPlanData>(
    null
  );
  const [isPersonalPlan, setIsPersonalPlan] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0);

    getPlansListApi()
      .then((res) => {
        setPlansList(res.data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const payload = {
      pageSize: pageSize,
      page: currentPage,
    };

    getFormTemplatesApi(payload).then((res) => {
      setFormTemplatesList(res.data.data.formTemplates);
      setTotalPages(Math.ceil(res.data.data.total / pageSize));
    });
  }, [currentPage]);

  useEffect(() => {
    if (!token || userRole[0] !== ROLES.ADMIN) {
      navigate(ROUTES.HOME);
    }
  }, [token]);

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleAddNewPlan = () => {
    setSelectedPlanData(null);
    setIsShowPlanEditModal(true);
  };

  const handleAddNewFormTemplate = () => {
    navigate(ROUTES.CREATE_FORM_TEMPLTE);
  };

  const publicPlans = plansList?.filter(
    (plan) => plan.userId === undefined && plan.isPublic
  );
  const personalPlans = plansList?.filter(
    (plan) => plan.userId !== undefined && !plan.isPublic
  );

  return (
    <>
      <div className={styles.outerWrapper}>
        <Helmet>
          <title>LeadQuiz | Admin page</title>
        </Helmet>
        <Header />
        <div className={styles.sectionWrapper}>
          <p className={styles.title}>Public plans:</p>
          <div className={styles.wrapper}>
            <button className={styles.button} onClick={handleAddNewPlan}>
              <p>Add new plan</p>
            </button>
            {publicPlans &&
              publicPlans.map((item, index) => (
                <Plan
                  key={index}
                  planData={item}
                  setIsShowAddSubmissionsModal={setIsShowAddSubmissionsModal}
                  setIsShowPlanEditModal={setIsShowPlanEditModal}
                  setSelectedPlanData={setSelectedPlanData}
                />
              ))}
          </div>
        </div>
        <div>
          <p className={styles.title}>Enterprise plans:</p>
          <PersonalPlans
            handleAddNewPlan={handleAddNewPlan}
            setIsPersonalPlan={setIsPersonalPlan}
            personalPlans={personalPlans}
            setIsShowAddSubmissionsModal={setIsShowAddSubmissionsModal}
            setIsShowPlanEditModal={setIsShowPlanEditModal}
            setSelectedPlanData={setSelectedPlanData}
          />
        </div>
        <div>
          <p className={styles.title}>Form templates:</p>
          <FormTemplates
            handleAddNewFormTemplate={handleAddNewFormTemplate}
            formTemplatesList={formTemplatesList}
          />
        </div>
        <div className={styles.paginationWrapper}>
          <Pagination
            handlePageChange={handlePageChange}
            currentPage={currentPage}
            totalPages={totalPages}
          />
        </div>
      </div>
      <AddSubmissionsModal
        isShowAddSubmissionsModal={isShowAddSubmissionsModal}
        handleCloseModal={() => setIsShowAddSubmissionsModal(false)}
        selectedPlanData={selectedPlanData}
      />
      <ConfigPlanModal
        isShowPlanEditModal={isShowPlanEditModal}
        handleCloseModal={() => setIsShowPlanEditModal(false)}
        selectedPlanData={selectedPlanData}
        setPlansList={setPlansList}
        isPersonalPlan={isPersonalPlan}
        setIsPersonalPlan={setIsPersonalPlan}
      />
    </>
  );
};

export default AdminPage;
