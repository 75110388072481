import React from "react";
import styles from "./modal.module.scss";
import { IModal } from "../interfaces/interfaces";
import crossIcon from "./../../assets/cross.svg";

const Modal: React.FC<IModal> = ({
  isShow,
  onClose,
  children,
  className,
}: IModal) => {
  if (!isShow) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <div className={`${styles.modal} ${className}`}>
        {onClose && (
          <button className={styles.modalClose} onClick={onClose}>
            <img src={crossIcon} alt="close" />
          </button>
        )}
        <div className={styles.modalContent}>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
