import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Papa from "papaparse";
import styles from "./downloader.module.scss";
import { CsvIcon } from "./icons/csv";
import { PdfIcon } from "./icons/pdf";

const Downloader: React.FC<any> = ({ leadsListForDownload, formName }: any) => {
  function downloadCSV(data: any) {
    const csv = Papa.unparse(data, {
      delimiter: ";",
    });
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "data.csv");
  }

  function downloadPDF(data: any[]) {
    if (data.length === 0) {
      console.log("No data to generate PDF");
      return;
    }

    const doc = new jsPDF();
    doc.text(formName || "Form Data", 20, 10);

    const headers = Object.keys(data[0]).map((key) => key);

    const body = data.map((item) => headers.map((header) => item[header]));

    doc.autoTable({
      head: [headers],
      body: body,
      startY: 20,
      theme: "grid",
      styles: {
        cellPadding: 1,
        fontSize: 6,
        overflow: "linebreak",
        valign: "middle",
      },
      headStyles: {
        fillColor: [54, 104, 255],
        textColor: [255, 255, 255],
        lineWidth: 0.1,
        lineColor: [100, 154, 255],
      },
      alternateRowStyles: {
        fillColor: [240, 240, 240],
      },
    });

    doc.save("data.pdf");
  }

  return (
    <div className={styles.downloadsWrapper}>
      <button
        onClick={() => downloadCSV(leadsListForDownload)}
        className={styles.downlaodButton}
      >
        <CsvIcon />
      </button>
      <button
        onClick={() => downloadPDF(leadsListForDownload)}
        className={styles.downlaodButton}
      >
        <PdfIcon />
      </button>
    </div>
  );
};

export default Downloader;
