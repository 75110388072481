import styles from "./getUp.module.scss";
import { useDispatch } from "react-redux";
import {
  setIsShowPricingModalAC,
  setIsShowRegisterModalAC,
} from "src/store/actions";

const GetUp = () => {
  const dispatch = useDispatch();

  const token = localStorage.getItem("token");

  return (
    <div className={styles.outerWrapper}>
      <div className={styles.wrapper}>
        <h6>Get up and running in less than 2 minutes.</h6>
        <div className={styles.button}>
          <button
            onClick={
              token
                ? () => dispatch(setIsShowPricingModalAC(true))
                : () => dispatch(setIsShowRegisterModalAC(true))
            }
          >
            Get started
          </button>
        </div>
      </div>
    </div>
  );
};

export default GetUp;
