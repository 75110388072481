import FormBuilder from "src/pages/formBuilder/formBuilder";
import styles from "./editFormTemplatePage.module.scss";

const EditFormTemplatePage = () => {
  return (
    <div className={styles.wrapper}>
      <FormBuilder editFormTemplateFlow />
    </div>
  );
};

export default EditFormTemplatePage;
