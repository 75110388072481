import React from "react";
import styles from "./input.module.scss";
import { IInput } from "../interfaces/interfaces";

const Input: React.FC<IInput> = ({
  placeholder,
  value,
  onChange,
  type = "text",
  maxLength,
  onFocus,
  onBlur,
  className,
  label,
  autoComplete
}: IInput) => {
  return (
    <div className={styles.inputWrapper}>
      {label && value && value !== "" && (
        <p className={styles.inputLabel}>{label}</p>
      )}
      <div className={styles.wrapper}>
        <input
          value={value}
          onChange={onChange}
          type={type}
          placeholder={placeholder}
          maxLength={maxLength}
          onFocus={onFocus}
          onBlur={onBlur}
          className={className}
          autoComplete={autoComplete}
        />
      </div>
    </div>
  );
};

export default Input;
