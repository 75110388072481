import { useEffect, useRef, useState } from "react";
import { RotatingLines } from 'react-loader-spinner';
import { useDispatch, useSelector } from "react-redux";
import Modal from "src/_elements/modal/modal";
import { setIsShowCalendlyModalAC } from "src/store/actions";
import styles from "./calendlyModal.module.scss";

const CalendlyModal = () => {
  const dispatch = useDispatch();
  const calendlyModalData = useSelector((state: any) => state.calendlyModalData);
  const calendlyContainerRef = useRef<HTMLDivElement | null>(null);
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);
  const [showLoader, setShowLoader] = useState(true);

  let minWidth = window.innerWidth <= 500 ? window.innerWidth : 300;
  let height = window.innerHeight <= 700 ? window.innerHeight : 700;

  useEffect(() => {
    setIsIframeLoaded(false);
    setShowLoader(true);

    const checkIframeLoaded = () => {
      const iframe = calendlyContainerRef.current?.querySelector("iframe");

      if (iframe) {
        const intervalId = setInterval(() => {
          try {
            if (iframe.contentWindow && iframe.contentWindow.document.readyState === 'complete') {
              setIsIframeLoaded(true);
              clearInterval(intervalId);
            }
          } catch (e) {
          }
        }, 100);
      }
    };

    if (calendlyModalData.show) {
      if (!document.querySelector('script[src="https://assets.calendly.com/assets/external/widget.js"]')) {
        const script = document.createElement('script');
        script.src = 'https://assets.calendly.com/assets/external/widget.js';
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
          checkIframeLoaded(); 
        };

        return () => {
          document.body.removeChild(script);
        };
      } else {
        checkIframeLoaded(); 
      }
    }
  }, [calendlyModalData]);

  useEffect(() => {
    if (!showLoader && isIframeLoaded) {
      setIsIframeLoaded(true);
    }
  }, [showLoader, isIframeLoaded]);

  return (
    <Modal
      className={styles.wrapper}
      isShow={calendlyModalData.show}
      onClose={() => dispatch(setIsShowCalendlyModalAC({ show: false, url: undefined }))}
    >
      <div className={styles.content}>
        {showLoader && (
          <div className={styles.loaderWrapper}>
            <RotatingLines
              visible={true}
              width="30"
              strokeWidth="5"
              animationDuration="0.75"
              strokeColor="black"
            />
          </div>
        )}

        <div
          ref={calendlyContainerRef}
          className={`${styles.calendlyWrapper} calendly-inline-widget`}
          data-url={calendlyModalData.url}
          style={{
            minWidth: `${minWidth}px`,
            height: `${height}px`,
            opacity: showLoader && isIframeLoaded ? 1 : 0,
            transition: "opacity 2s cubic",
            transitionDelay: "3s"
          }}
        ></div>
      </div>
    </Modal>
  );
};

export default CalendlyModal;
