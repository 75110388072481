import styles from "./integrates.module.scss";
import facebookIcon from "src/assets/homePage/facebook.png";
import googleAds from "src/assets/homePage/googleAds.png";
import zapier from "src/assets/homePage/zapier.png";
import jobber from "src/assets/homePage/jobber.png";
import workiz from "src/assets/homePage/workiz.jpg";
import leadConnector from "src/assets/homePage/leadConnector.jpg";

const Integrates = () => {
  return (
    <div className={styles.wrapper}>
      <h2>Integrates with</h2>
      <div className={styles.iconsWrapper}>
        <img src={facebookIcon} alt="facebook" />
        <img src={googleAds} alt="google ads" />
        <img src={zapier} alt="zapier" />
        <img src={jobber} alt="jobber" />
        <img src={workiz} alt="workiz" />
        <img src={leadConnector} alt="lead connector" />
      </div>
      <p>and more...</p>
    </div>
  );
};

export default Integrates;
