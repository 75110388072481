import React, { useEffect, useState } from "react";
import styles from "./answerOptionsModal.module.scss";
import Modal from "src/_elements/modal/modal";
import AddAnswerOptionButton from "./addAnswerOptionButton/addAnswerOptionButton";
import AnswerOptionPreview from "./answerOptionPreview/answerOptionPreview";
import { useDispatch, useSelector } from "react-redux";
import {
  addAnswerOptionAC,
  setIsAnyAnswerOptionsAC,
  setIsMultipleSelectAnswerAC,
  setQuestionsAC,
} from "src/store/actions";
import { truncateText } from "src/_utils/truncateText";
import ToggleSwitch from "src/_elements/toggleSwitch/toggleSwitch";

const AnswerOptionsModal: React.FC<any> = ({
  index,
  question,
  showAnswerOptionsModal,
  setShowAnswerOptionsModal,
}: any) => {
  const dispatch = useDispatch();
  const questionsList = useSelector((state: any) => state.questionsList);

  const [newAnswerOptions, setNewAnswerOptions] = useState<string[]>([]);

  const toggleMultipleSelect = (value: boolean) => {
    dispatch(setIsMultipleSelectAnswerAC(index, value));
  };

  const handleAddAnswerOption = (index: number) => {
    setNewAnswerOptions([...newAnswerOptions, ""]);

    const answerOptionId =
      index +
      "." +
      (!questionsList[index]?.answerOptions?.length
        ? 0
        : questionsList[index]?.answerOptions?.length);

    dispatch(addAnswerOptionAC(index, "", answerOptionId));

    dispatch(setIsAnyAnswerOptionsAC(true));
  };

  const handleRemoveAnswerOption = (
    questionIndex: number,
    optionIndex: number
  ) => {
    const newQuestionsList = [...questionsList];
    newQuestionsList[questionIndex].answerOptions.splice(optionIndex, 1);
    dispatch(setQuestionsAC(newQuestionsList));
  };

  const handleCloseModal = () => {
    setNewAnswerOptions([]);
    setShowAnswerOptionsModal(false);
  };

  return (
    <Modal isShow={showAnswerOptionsModal} onClose={handleCloseModal}>
      <p className={styles.title}>
        Set answer options for question: <br />
        <span>"{truncateText(question, 100)}"</span>
      </p>
      <div className={styles.multipleSelectToggleWrap}>
        <p>multiple select</p>
        <ToggleSwitch
          value={questionsList[index]?.isMultipleSelect}
          toggleChange={toggleMultipleSelect}
        />
      </div>
      <div className={styles.answerOptionsWrapper}>
        {questionsList[index].answerOptions &&
          questionsList[index].answerOptions.map(
            (option: any, optionIndex: number) => (
              <AnswerOptionPreview
                questionIndex={index}
                optionIndex={optionIndex}
                questionText={option.title}
                questionIcon={option.icon}
                handleRemoveAnswerOption={handleRemoveAnswerOption}
                index={index}
                key={optionIndex}
              />
            )
          )}
        <AddAnswerOptionButton onClick={() => handleAddAnswerOption(index)}>
          + Add answer option
        </AddAnswerOptionButton>
      </div>
      <div className={styles.btnWrap}>
        <button onClick={handleCloseModal}>Save</button>
      </div>
    </Modal>
  );
};

export default AnswerOptionsModal;
