import Header from "../homePage/header/header";
import styles from "./forAgencies.module.scss";
import { Helmet } from "react-helmet";
import Top from "./top/top";
import Empower from "./empower/empower";
import ContentSection from "../whoWeServe/tradePage/contentSection/contentSection";
import chartIcon from "src/assets/chart.svg";
import easilyCollectInformationImg from "src/assets/whoWeServe/trades/easilyCollectInformation.png";
import crmImg from "src/assets/crm.svg";
import skyIcon from "src/assets/whoWeServe/trades/sky.svg";
import profitIcon from "src/assets/profit.svg";
import increaseLeadQualityImg from "src/assets/whoWeServe/trades/increaseLeadQuality.png";
import rocketImg from "src/assets/rocket.svg";
import arrowUpIcon from "src/assets/arrowUp.svg";
import Partner from "./partner/partner";
import Footer from "../homePage/footer/footer";
import { useEffect } from "react";

const ForAgencies = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.outerWrapper}>
      <Helmet>
        <title>LeadQuiz | For agencies</title>
      </Helmet>
      <Header />
      <Top />
      <div className={styles.gradient} />
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <Empower />
          <div className={styles.contentWrap}>
            <ContentSection
              icon={chartIcon}
              title="Custom Solutions for Every Client"
              img={easilyCollectInformationImg}
              description="Whether you're working with home service providers, e-commerce businesses, or local brands, LeadQuiz offers a versatile and intuitive builder that requires no coding knowledge. Easily create branded quizzes and lead forms that align with your clients’ industry and target audience. You can quickly adapt the forms to different niches, saving time while maximizing lead generation potential."
            />
            <ContentSection
              isReversed
              icon={skyIcon}
              title="Seamless CRM Integration"
              img={crmImg}
              description="Forget manual data entry—LeadQuiz seamlessly integrates with the CRMs your clients already use. Whether it's <b>HubSpot</b>, <b>Jobber</b>, <b>LeadConnector</b>, or others, our <b>Zapier</b> integration ensures lead data is instantly synced. This keeps your clients updated in real-time with accurate, validated lead information, enhancing their sales process and allowing you to deliver better value."
            />
            <ContentSection
              icon={profitIcon}
              title="Higher Quality Leads for Your Clients"
              img={increaseLeadQualityImg}
              description="Our smart forms require more intent from users, helping you filter out lower-quality leads and improve the chances of conversion. By validating essential details like phone numbers and emails, LeadQuiz ensures that your clients receive actionable, ready-to-buy leads. Say goodbye to generic Facebook leads and hello to qualified prospects."
            />
            <ContentSection
              isReversed
              icon={arrowUpIcon}
              title="Boost Your Agency’s Reputation"
              img={rocketImg}
              description="By leveraging LeadQuiz, you can offer more than just leads to your clients—you’ll provide high-quality prospects that match their service offerings. As a result, you’ll boost their marketing performance and position your agency as a valuable partner in their growth journey."
            />
          </div>
          <Partner />
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default ForAgencies;
