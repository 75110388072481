import React from "react";
import styles from "./chooseEndingPage.module.scss";
import TextArea from "src/_elements/textArea/textArea";
import { useDispatch, useSelector } from "react-redux";
import { setEndingPageTextAC } from "src/store/actions";

const ChooseEndingPage = () => {
  const dispatch = useDispatch();

  const endingPageText = useSelector((state: any) => state.endingPageText);

  return (
    <div className={styles.outerWrapper}>
      <div className={styles.wrapper}>
        <h1>Enter ending page text</h1>
        <div className={styles.content}>
          <TextArea
            placeholder="Ending page text"
            value={endingPageText}
            onChange={(e: any) => dispatch(setEndingPageTextAC(e.target.value))}
          />
        </div>
      </div>
    </div>
  );
};

export default ChooseEndingPage;
