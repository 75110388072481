import React, { useEffect, useState } from "react";
import styles from "./chooseConditions.module.scss";
import { useDispatch, useSelector } from "react-redux";
import ConditionsModal from "./conditionsModal/conditionsModal";
import { setCurrentCreateFormPageAC } from "src/store/actions";
import FormInput from "src/_elements/formInput/formInput";
import { IQuestion } from "../../interfaces/interfaces";

const ChooseConditions = () => {
  const dispatch = useDispatch();

  const questionsList = useSelector((state: any) => state.questionsList);
  const isAnyAnswerOptions = useSelector(
    (state: any) => state.isAnyAnswerOptions
  );
  const currentIndex = useSelector((state: any) => state.currentCreateFormPage);

  const [showConditionsModal, setShowConditionsModal] = useState(false);
  const [currentQuestionId, setCurrentQuestionId] = useState<number | null>(
    null
  );
  const [selectedQuestion, setSelectedQuestion] = useState("");

  useEffect(() => {
    if (!isAnyAnswerOptions) {
      dispatch(setCurrentCreateFormPageAC(currentIndex + 1));
    }
  }, [isAnyAnswerOptions]);

  const handleOpenModal = (questionId: number | null, question: string) => {
    setCurrentQuestionId(questionId);
    setSelectedQuestion(question);
    setShowConditionsModal(true);
  };

  return (
    <div className={styles.outerWrapper}>
      <div className={styles.wrapper}>
        <h1>Set logic for questions (optional)</h1>
        <p>Select the questions for which you want to add logic</p>
        <div className={styles.content}>
          {questionsList
            .slice(
              Math.max(
                1,
                questionsList.findIndex(
                  (el: IQuestion) =>
                    el.answerOptions && el.answerType === "answerOptions"
                ) + 1
              )
            )
            .map((el: IQuestion, index: number) => (
              <div
                onClick={() => handleOpenModal(el.id, el.question)}
                key={el.id}
                className={styles.questionWrapper}
              >
                <div className={styles.questionInnerWrap}>
                  <FormInput
                    placeholder={`Question ${index + 1}`}
                    value={el.question}
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
      <ConditionsModal
        showConditionsModal={showConditionsModal}
        setShowConditionsModal={setShowConditionsModal}
        questionId={currentQuestionId}
        selectedQuestion={selectedQuestion}
      />
    </div>
  );
};

export default ChooseConditions;
