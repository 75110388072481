import React from "react";
import styles from "./addAnswerOptionButton.module.scss";

const AddAnswerOptionButton: React.FC<any> = ({ children, onClick }: any) => {
  return (
    <button onClick={onClick} className={styles.wrapper}>
      {children}
    </button>
  );
};

export default AddAnswerOptionButton;
