import styles from "./activeAdditionalPurchases.module.scss";

const ActiveAdditionalPurchases: React.FC<any> = ({
  additionalPurchasesListArray,
}: any) => {
  return (
    <div className={styles.additionalPurchasesWrapper}>
      <p className={styles.title}>Your active additional purchases:</p>
      <div className={styles.additionalPurchasesListWrapper}>
        {additionalPurchasesListArray}
      </div>
    </div>
  );
};

export default ActiveAdditionalPurchases;
