import React from "react";
import styles from "./textArea.module.scss";
import { ITextArea } from "../interfaces/interfaces";

const TextArea: React.FC<ITextArea> = ({
  placeholder,
  value,
  onChange,
  label,
}: ITextArea) => {
  return (
    <div className={styles.wrapper}>
      {label && value && value !== "" && (
        <p className={styles.inputLabel}>{label}</p>
      )}
      <textarea value={value} onChange={onChange} placeholder={placeholder} />
    </div>
  );
};

export default TextArea;
