import React, { useEffect, useState } from "react";
import styles from "./endingPage.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RotatingLines } from "react-loader-spinner";
import { setCompanyLogoAC, setCompanyNameAC } from "src/store/actions";
import ROUTES from "src/_utils/routes/routes";
import {
  getUserAnswersForFormApi,
  patchFormApi,
  patchFormTemplateApi,
  postFormApi,
  postFormTemplateApi,
  uploadFileApi,
} from "src/_utils/api/api";
import { IPostFormPayload } from "src/_utils/interfaces/interfaces";
import ActionModal from "src/_elements/actionModal/actionModal";

const EndingPage: React.FC<any> = ({
  editFormsData,
  createFormTemplateFlow,
  editFormTemplateFlow,
  currentFormTemplateImg,
}: any) => {
  const questionsList = useSelector((state: any) => state.questionsList);
  const companyName = useSelector((state: any) => state.companyName);
  const companyLogo = useSelector((state: any) => state.companyLogo);
  const endingPageText = useSelector((state: any) => state.endingPageText);
  const HSWCYOtherInfo = useSelector((state: any) => state.HSWCYOtherInfo);
  const HSWCYOTitle = useSelector((state: any) => state.HSWCYOTitle);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isFormSaved, setIsFormSaved] = useState(false);
  const [isFormTemplateSaved, setIsFormTemplateSaved] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isShowCopyFormModal, setIsShowCopyFormModal] = useState(false);
  const [isCopyForm, setIsCopyForm] = useState(false);
  const [currentElement, setCurrentElement] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      isFormSaved && navigate(ROUTES.FORMS);
    }, 1000);
  }, [isFormSaved]);

  useEffect(() => {
    setTimeout(() => {
      isFormTemplateSaved && navigate(ROUTES.ADMIN_PAGE);
    }, 1000);
  }, [isFormTemplateSaved]);

  useEffect(() => {
    if (isCopyForm) {
      if (companyLogo) {
        uploadImageAndForm(currentElement);
      } else {
        uploadFormOnly();
      }
    }
  }, [isCopyForm, currentElement]);

  questionsList.map(
    (el: any) =>
      el.answerOptions &&
      el.answerOptions.map((option: any) => {
        if (option.icon && option.icon.startsWith("blob")) {
          const formData = new FormData();
          formData.append("file", option.iconFile);

          uploadFileApi(formData)
            .then((res) => {
              option.icon = res.data.data.fileUrl;
            })
            .catch((err) => console.log(err));
        }
      })
  );

  questionsList.map((el: any) => {
    el?.answerOptions?.map((a: any) => {
      if (a.title === "") {
        a.title = "Answer option";
      }
    });
  });

  const handleCreateNewFormTemplate = (e: any) => {
    setIsLoading(true);

    e.preventDefault();
    const formData = new FormData();
    formData.append("file", companyLogo);

    uploadFileApi(formData)
      .then((res) => {
        const payload = {
          name: companyName,
          formsData: [
            {
              logo: res.data.data.fileUrl,
              questions: questionsList,
              endingPageText: endingPageText,
              howShallWeContactYou: {
                title: HSWCYOTitle,
                otherInfo: HSWCYOtherInfo,
              },
            },
          ],
        };

        postFormTemplateApi(payload).then(() => {
          setIsLoading(false);
          dispatch(setCompanyNameAC(""));
          dispatch(setCompanyLogoAC(null));
          setIsFormTemplateSaved(true);
          setIsFormSaved(true);
        });
      })
      .catch((err) => console.log(err));
  };

  const handleCloseCopyModal = () => {
    setIsShowCopyFormModal(false);
  };

  const handleCopyForm = () => {
    setIsCopyForm(true);
    handleCloseCopyModal();
  };

  const uploadImageAndForm = (e: any) => {
    setIsLoading(true);

    e.preventDefault();
    const formData = new FormData();
    formData.append("file", companyLogo);

    uploadFileApi(formData)
      .then((res) => {
        const payload: IPostFormPayload = {
          name: companyName,
          formsData: {
            logo: res.data.data.fileUrl,
            questions: questionsList,
            endingPageText: endingPageText,
            howShallWeContactYou: {
              title: HSWCYOTitle,
              otherInfo: HSWCYOtherInfo,
            },
          },
        };

        postFormApi(payload).then(() => {
          setIsLoading(false);
          dispatch(setCompanyNameAC(""));
          dispatch(setCompanyLogoAC(null));
          setIsFormSaved(true);
        });
      })
      .catch((err) => console.log(err));
  };

  const uploadFormOnly = () => {
    setIsLoading(true);

    const payload: IPostFormPayload = {
      name: companyName,
      formsData: {
        logo: editFormsData.formData.formsData.logo,
        questions: questionsList,
        endingPageText: endingPageText,
        howShallWeContactYou: {
          title: HSWCYOTitle,
          otherInfo: HSWCYOtherInfo,
        },
      },
    };

    postFormApi(payload).then(() => {
      setIsLoading(false);
      dispatch(setCompanyNameAC(""));
      dispatch(setCompanyLogoAC(null));
      setIsFormSaved(true);
    });
  };

  const patchImageAndForm = (e: any) => {
    setIsLoading(true);

    e.preventDefault();
    const formData = new FormData();
    formData.append("file", companyLogo);

    uploadFileApi(formData)
      .then((res) => {
        const payload: IPostFormPayload = {
          name: companyName,
          formsData: {
            logo: res.data.data.fileUrl,
            questions: questionsList,
            endingPageText: endingPageText,
            howShallWeContactYou: {
              title: HSWCYOTitle,
              otherInfo: HSWCYOtherInfo,
            },
          },
        };

        patchFormApi(payload, id).then(() => {
          setIsLoading(false);
          dispatch(setCompanyNameAC(""));
          dispatch(setCompanyLogoAC(null));
          setIsFormSaved(true);
        });
      })
      .catch((err) => console.log(err));
  };

  const patchFormOnly = () => {
    setIsLoading(true);

    const payload: IPostFormPayload = {
      name: companyName,
      formsData: {
        logo: editFormsData.formData.formsData.logo,
        questions: questionsList,
        endingPageText: endingPageText,
        howShallWeContactYou: {
          title: HSWCYOTitle,
          otherInfo: HSWCYOtherInfo,
        },
      },
    };

    patchFormApi(payload, id).then(() => {
      setIsLoading(false);
      dispatch(setCompanyNameAC(""));
      dispatch(setCompanyLogoAC(null));
      setIsFormSaved(true);
    });
  };

  const patchImageAndFormTemplate = (e: any) => {
    setIsLoading(true);

    e.preventDefault();
    const formData = new FormData();
    formData.append("file", companyLogo);

    uploadFileApi(formData)
      .then((res) => {
        const payload = {
          name: companyName,
          formsData: [
            {
              logo: res.data.data.fileUrl,
              questions: questionsList,
              endingPageText: endingPageText,
              howShallWeContactYou: {
                title: HSWCYOTitle,
                otherInfo: HSWCYOtherInfo,
              },
            },
          ],
        };

        patchFormTemplateApi(payload, id).then(() => {
          setIsLoading(false);
          dispatch(setCompanyNameAC(""));
          dispatch(setCompanyLogoAC(null));
          setIsFormSaved(true);
          setIsFormTemplateSaved(true);
        });
      })
      .catch((err) => console.log(err));
  };

  const patchFormTemplateOnly = () => {
    setIsLoading(true);

    const payload = {
      name: companyName,
      formsData: [
        {
          logo: currentFormTemplateImg,
          questions: questionsList,
          endingPageText: endingPageText,
          howShallWeContactYou: {
            title: HSWCYOTitle,
            otherInfo: HSWCYOtherInfo,
          },
        },
      ],
    };

    patchFormTemplateApi(payload, id).then(() => {
      setIsLoading(false);
      dispatch(setCompanyNameAC(""));
      dispatch(setCompanyLogoAC(null));
      setIsFormSaved(true);
      setIsFormTemplateSaved(true);
    });
  };

  const saveForm = (e: any) => {
    setErrorMessage("");
    setCurrentElement(e);

    const payload = {
      formId: editFormsData?.form?.id,
      pageSize: 1,
      page: 1,
    };

    if (editFormsData) {
      // is editing form
      getUserAnswersForFormApi(payload).then((res: any) => {
        if (res.data.data.formsData[0]) {
          // if has some submissions check
          setIsShowCopyFormModal(true);
        } else {
          if (companyLogo) {
            patchImageAndForm(e);
          } else {
            patchFormOnly();
          }
        }
      });
    } else if (createFormTemplateFlow) {
      handleCreateNewFormTemplate(e);
    } else if (editFormTemplateFlow) {
      if (companyLogo) {
        patchImageAndFormTemplate(e);
      } else {
        patchFormTemplateOnly();
      }
    } else {
      uploadImageAndForm(e); //create new form
    }
  };

  return (
    <>
      {" "}
      <div className={styles.outerWrapper}>
        <div className={styles.wrapper}>
          <h4>
            {createFormTemplateFlow || editFormTemplateFlow
              ? "Your template is ready"
              : "Your form is ready"}
          </h4>
          {!isFormSaved && (
            <button onClick={saveForm} className={styles.button}>
              {isLoading ? (
                <RotatingLines
                  visible={true}
                  width="30"
                  strokeWidth="5"
                  animationDuration="0.75"
                  strokeColor="white"
                />
              ) : (
                "Save"
              )}
            </button>
          )}
          {isFormSaved && (
            <p className={styles.formSavedLabel}>
              {createFormTemplateFlow || editFormTemplateFlow
                ? "Template saved"
                : "Form saved"}
            </p>
          )}
          {errorMessage !== "" && (
            <p className={styles.formSaveErrorLabel}>{errorMessage}</p>
          )}
        </div>
      </div>
      <ActionModal
        title={`The ${editFormsData?.form.name} form has submissions.`}
        subtitle="This form has submissions, so this form will be copied and you will be able to edit the copy of this form."
        isShowModal={isShowCopyFormModal}
        handleCloseModal={handleCloseCopyModal}
        handleAction={handleCopyForm}
        actionButtonText="Ok"
      />
    </>
  );
};

export default EndingPage;
