import { useEffect, useState } from "react";
import styles from "./trackingAndTagging.module.scss";
import faceBookPixelImg from "src/assets/settingsPage/facebookPixel.png";
import googleAdsImg from "src/assets/settingsPage/googleAds.jpg";
import Input from "src/_elements/input/input";
import {
  deleteFacebookPixelIdApi,
  deleteGoogleAdsIdApi,
  getFacebookPixelIdApi,
  getGoogleAdsIdApi,
  postFacebookPixelIdApi,
  postGoogleAdsIdApi,
} from "src/_utils/api/api";
import { RotatingLines } from "react-loader-spinner";
import trashIcon from "src/assets/trash.svg";
import ActionModal from "src/_elements/actionModal/actionModal";

const TrackingAndTagging = () => {
  const [facebookPixelId, setFacebookPixelId] = useState("");
  const [googleAdslId, setGoogleAdslId] = useState("");
  const [googleAdslLabel, setGoogleAdslLabel] = useState("");
  const [isFacebookPixelLoading, setFacebookPixelLoading] = useState(false);
  const [isGoogleAdsLoading, setIsGoogleAdsLoading] = useState(false);
  const [isFacebookPixelSaved, setIsFacebookPixelSaved] = useState(false);
  const [isGoogleAdsSaved, setIsGoogleAdsSaved] = useState(false);
  const [facebookPixelErrorMessage, setFacebookPixelErrorMessage] =
    useState("");
  const [googleAdsErrorMessage, setGoogleAdsErrorMessage] = useState("");
  const [isShowRemoveFacebookPixelId, setIsShowRemoveFacebookPixelId] =
    useState(false);
  const [isShowRemoveGoogleAds, setIsShowRemoveGoogleAds] = useState(false);

  setTimeout(() => {
    setIsFacebookPixelSaved(false);
    setIsGoogleAdsSaved(false);
  }, 3000);

  useEffect(() => {
    getFacebookPixelIdApi().then((res) => {
      setFacebookPixelId(res.data.data?.facebookPixelId);
    });
    getGoogleAdsIdApi().then((res) => {
      setGoogleAdslId(res.data.data?.conversionId);
      setGoogleAdslLabel(res.data.data?.conversionLabel);
    });
  }, []);

  const handleSaveFacebookPixelId = () => {
    if (!facebookPixelId) {
      setFacebookPixelErrorMessage("Facebook pixel ID can't be empty");
    } else {
      setFacebookPixelErrorMessage("");
      setFacebookPixelLoading(true);

      const payload = {
        facebookPixelId: facebookPixelId,
      };

      postFacebookPixelIdApi(payload).then(() => {
        setFacebookPixelLoading(false);
        setIsFacebookPixelSaved(true);
      });
    }
  };

  const handleSaveGoogleAds = () => {
    if (!googleAdslId) {
      setGoogleAdsErrorMessage("Conversion ID can't be empty");
    } else if (!googleAdslLabel) {
      setGoogleAdsErrorMessage("Conversion label can't be empty");
    } else {
      setGoogleAdsErrorMessage("");
      setIsGoogleAdsLoading(true);

      const payload = {
        conversionId: googleAdslId,
        conversionLabel: googleAdslLabel,
      };

      postGoogleAdsIdApi(payload).then(() => {
        setIsGoogleAdsLoading(false);
        setIsGoogleAdsSaved(true);
      });
    }
  };

  const handleChangeFacebookPixelId = (value: string) => {
    if (value.length > 18) {
      setFacebookPixelErrorMessage(
        "Facebook pixel ID length must be less than or equal to 18 characters long"
      );
    } else if (/^\d*$/.test(value)) {
      setFacebookPixelErrorMessage("");
      setFacebookPixelId(value);
    }
  };

  const handleChangeGoogleAdslId = (value: string) => {
    setGoogleAdslId(value);
  };

  const handleChangeGoogleAdslLabel = (value: string) => {
    setGoogleAdslLabel(value);
  };

  const handleRemoveFacebookPixelId = () => {
    deleteFacebookPixelIdApi();
    setFacebookPixelId("");
    setFacebookPixelErrorMessage("");
    setIsShowRemoveFacebookPixelId(false);
  };

  const handleRemoveGoogleAds = () => {
    deleteGoogleAdsIdApi();
    setGoogleAdslId("");
    setGoogleAdslLabel("");
    setGoogleAdsErrorMessage("");
    setIsShowRemoveGoogleAds(false);
  };

  const handleCloseRemoveFacebookPixelIdModal = () => {
    setIsShowRemoveFacebookPixelId(false);
  };

  const handleCloseRemoveGoogleAdsModal = () => {
    setIsShowRemoveGoogleAds(false);
  };

  return (
    <>
      <div className={styles.wrapper}>
        <p className={styles.title}>Tracking & Tagging</p>
        <div className={styles.content}>
          <div className={styles.item}>
            <div className={styles.imgWrap}>
              <img width="180px" src={faceBookPixelImg} alt="facebook pixel" />
            </div>
            <div className={styles.inputsOuterWrap}>
              <div className={styles.inputs}>
                {facebookPixelErrorMessage && (
                  <p className={styles.errorMessage}>
                    {facebookPixelErrorMessage}
                  </p>
                )}
                {isFacebookPixelSaved && (
                  <p className={styles.savedMessage}>Saved!</p>
                )}
                <Input
                  value={facebookPixelId}
                  onChange={(e) => handleChangeFacebookPixelId(e.target.value)}
                  placeholder="Pixel ID"
                  label="Pixel ID"
                />
                <button
                  onClick={handleSaveFacebookPixelId}
                  className={styles.saveBtn}
                >
                  {!isFacebookPixelLoading ? (
                    "Save"
                  ) : (
                    <RotatingLines
                      visible={true}
                      width="30"
                      strokeWidth="5"
                      animationDuration="0.75"
                      strokeColor="white"
                    />
                  )}
                </button>
              </div>
              <button
                onClick={() => setIsShowRemoveFacebookPixelId(true)}
                className={styles.deleteBtn}
              >
                <img src={trashIcon} alt="remove" />
              </button>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.imgWrap}>
              <img width="140px" src={googleAdsImg} alt="google ads" />
            </div>
            <div className={styles.inputsOuterWrap}>
              <div className={styles.inputs}>
                {googleAdsErrorMessage && (
                  <p className={styles.errorMessage}>{googleAdsErrorMessage}</p>
                )}
                {isGoogleAdsSaved && (
                  <p className={styles.savedMessage}>Saved!</p>
                )}
                <Input
                  value={googleAdslId}
                  onChange={(e) => handleChangeGoogleAdslId(e.target.value)}
                  placeholder="Conversion ID"
                  label="Conversion ID"
                />
                <Input
                  value={googleAdslLabel}
                  onChange={(e) => handleChangeGoogleAdslLabel(e.target.value)}
                  placeholder="Conversion label"
                  label="Conversion label"
                />
                <button
                  onClick={handleSaveGoogleAds}
                  className={styles.saveBtn}
                >
                  {!isGoogleAdsLoading ? (
                    "Save"
                  ) : (
                    <RotatingLines
                      visible={true}
                      width="30"
                      strokeWidth="5"
                      animationDuration="0.75"
                      strokeColor="white"
                    />
                  )}
                </button>
              </div>
              <button
                onClick={() => setIsShowRemoveGoogleAds(true)}
                className={styles.deleteBtn}
              >
                <img src={trashIcon} alt="remove" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <ActionModal
        title={`Remove facebook pixel id?"`}
        subtitle="This will remove your facebook pixel id."
        isShowModal={isShowRemoveFacebookPixelId}
        handleCloseModal={handleCloseRemoveFacebookPixelIdModal}
        handleAction={handleRemoveFacebookPixelId}
        actionButtonText="Remove"
      />
      <ActionModal
        title={`Remove google ads?"`}
        subtitle="This will remove your google ads."
        isShowModal={isShowRemoveGoogleAds}
        handleCloseModal={handleCloseRemoveGoogleAdsModal}
        handleAction={handleRemoveGoogleAds}
        actionButtonText="Remove"
      />
    </>
  );
};

export default TrackingAndTagging;
