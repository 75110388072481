import styles from "./empower.module.scss";
import leadGenerationIcon from "src/assets/leadGeneration.svg";
import customizeQquizzesAndForms from "src/assets/customizeQquizzesAndForms.svg";
import paying from "src/assets/paying.svg";

const Empower = () => {
  return (
    <div className={styles.wrapper}>
      <h2>Empower Your Agency with LeadQuiz</h2>
      <div className={styles.divider} />
      <p className={styles.subtitle}>
        Attracting high-quality leads for your clients has never been easier
      </p>
      <div className={styles.itemsWrap}>
        <div className={styles.item}>
          <img src={leadGenerationIcon} alt="lead generation solutions" />
          <p>Create tailored lead generation solutions</p>
        </div>
        <div className={styles.item}>
          <img
            src={customizeQquizzesAndForms}
            alt="Customize quizzes and forms"
          />
          <p>
            Customize quizzes and forms to match your client’s unique business
            needs
          </p>
        </div>
        <div className={styles.item}>
          <img src={paying} alt="Paying" />
          <p>Convert more leads into paying customers</p>
        </div>
      </div>
    </div>
  );
};

export default Empower;
