import React, { useEffect, useState } from "react";
import styles from "./formTemplatesModal.module.scss";
import Modal from "src/_elements/modal/modal";
import { getFormTemplatesApi } from "src/_utils/api/api";
import { Link } from "react-router-dom";
import ROUTES from "src/_utils/routes/routes";
import FormTemplateItem from "./formTemplateItem/formTemplateItem";
import { useDispatch } from "react-redux";
import { setSelectedFormTemplateIdAC } from "src/store/actions";
import Pagination from "src/pages/leads/pagination/pagination";

const FormTemplatesModal: React.FC<any> = ({ isShow, setIsShow }: any) => {
  const dispatch = useDispatch();

  const pageSize = 4;

  const [formTemplates, setformTemplates] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const payload = {
      pageSize: pageSize,
      page: currentPage,
    };

    getFormTemplatesApi(payload).then((res) => {
      setformTemplates(res.data.data.formTemplates);
      setTotalPages(Math.ceil(res.data.data.total / pageSize));
    });
  }, [currentPage]);

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const formTemplatesList = formTemplates?.map((res: any, index: number) => (
    <Link
      key={index}
      to={`${ROUTES.FORM_BUILDER}/?template-id=${res.id}`}
      onClick={() => dispatch(setSelectedFormTemplateIdAC(res.id))}
    >
      <FormTemplateItem title={res.name} img={res.formsData[0].logo} />
    </Link>
  ));

  return (
    <Modal
      className={styles.wrapper}
      isShow={isShow}
      onClose={() => setIsShow(false)}
    >
      <div className={styles.content}>
        <p className={styles.title}>Create your form:</p>
        <Link to={ROUTES.FORM_BUILDER} className={styles.btn}>
          Create form
        </Link>
        <p>Or use a ready-made template:</p>
        <div className={styles.formTemplatesListWrapper}>
          {formTemplatesList}
        </div>
        <div className={styles.paginationWrapper}>
          <Pagination
            handlePageChange={handlePageChange}
            currentPage={currentPage}
            totalPages={totalPages}
          />
        </div>
      </div>
    </Modal>
  );
};

export default FormTemplatesModal;
