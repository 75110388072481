import { jwtDecode } from "jwt-decode";
import { Navigate, Route, Routes } from "react-router-dom";
import ROUTES from "./_utils/routes/routes";
import styles from "./App.module.scss";
import AdminPage from "./pages/adminPage/adminPage";
import EditFormTemplatePage from "./pages/adminPage/formTemplates/formTemplateItem/editFormTemplatePage/editFormTemplatePage";
import GoogleLogin from "./pages/auth/googleLogin/googleLogin";
import LogIn from "./pages/auth/login/login";
import ActivateUserPage from "./pages/auth/signup/activateUserPage/activateUserPage";
import SignUp from "./pages/auth/signup/signup";
import ChangeEmail from "./pages/changeEmail/changeEmail";
import ForgotPassword from "./pages/forgotPassword/forgotPassword";
import ResetPassword from "./pages/forgotPassword/resetPassword/resetPassword";
import FormBuilder from "./pages/formBuilder/formBuilder";
import CreateFormTemplate from "./pages/forms/createFormTemplate/createFormTemplate";
import EditForm from "./pages/forms/editForm/editForm";
import Forms from "./pages/forms/forms";
import FormView from "./pages/formView/formView";
import HomePage from "./pages/homePage/homePage";
import HowItWorks from "./pages/howItWorks/howItWorks";
import LeadsPage from "./pages/leads/leadsPage";
import PaymentSuccess from "./pages/paymentSuccess/paymentSuccess";
import Pricing from "./pages/pricing/pricing";
import PrivacyPolicy from "./pages/privacy-policy/privacy-policy";
import SettingsPage from "./pages/settingPage/settingsPage";
import TermsOfUse from "./pages/terms-of-use/terms-of-use";
import TradePage from "./pages/whoWeServe/tradePage/tradePage";
import WhoWeServe from "./pages/whoWeServe/whoWeServe";
import ForAgencies from "./pages/forAgencies/forAgencies";

function App() {
  const token = localStorage.getItem("token");

  if (token) {
    const decodedToken = jwtDecode(token);
    //@ts-ignore
    const expirationDate = decodedToken.exp * 1000;

    if (Date.now() >= expirationDate) {
      localStorage.removeItem("token");
    }
  }

  const hostname = window.location.hostname;
  const cleanedHostname = hostname.startsWith("www.")
    ? hostname.slice(4)
    : hostname;
  const subdomain = cleanedHostname.split(".")[0];

  if (subdomain === process.env.REACT_APP_DOMAIN?.split(".")[0]) {
    return (
      <div className={styles.wrapper}>
        <Routes>
          <Route path={ROUTES.HOME} element={<HomePage />} />

          <Route path={ROUTES.HOW_IT_WORKS} element={<HowItWorks />} />
          <Route path={ROUTES.WHO_WE_SERVE} element={<WhoWeServe />} />
          <Route path={ROUTES.PRICING} element={<Pricing />} />

          <Route path={ROUTES.FORM_BUILDER} element={<FormBuilder />} />
          <Route path={ROUTES.FORMS} element={<Forms />} />

          <Route path={ROUTES.SIGNUP} element={<SignUp />} />
          <Route path={ROUTES.LOGIN} element={<LogIn />} />

          <Route path={ROUTES.ACTIVATE_USER} element={<ActivateUserPage />} />

          <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
          <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />

          <Route path={ROUTES.CHANGE_EMAIL} element={<ChangeEmail />} />

          <Route path={ROUTES.GOOGLE_AUTH} element={<GoogleLogin />} />

          <Route path={ROUTES.ADMIN_PAGE} element={<AdminPage />} />

          <Route path={ROUTES.FOR_AGENCIES} element={<ForAgencies />} />

          <Route path={ROUTES.SETTINGS} element={<SettingsPage />} />

          <Route path={ROUTES.LEADS} element={<LeadsPage />} />

          <Route path={`${ROUTES.FORMS}/:id`} element={<FormView />} />

          <Route path={`${ROUTES.EDIT_FORM}/:id`} element={<EditForm />} />
          <Route
            path={`${ROUTES.CREATE_FORM_TEMPLTE}`}
            element={<CreateFormTemplate />}
          />
          <Route
            path={`${ROUTES.EDIT_FORM_TEMPLTE}/:id`}
            element={<EditFormTemplatePage />}
          />

          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.GENERAL_CONTRACTING}`}
            element={<TradePage tradeName="General Contracting" />}
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.HVAC}`}
            element={
              <TradePage tradeName="HVAC (Heating, Ventilation, and Air Conditioning)" />
            }
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.ROOFING}`}
            element={<TradePage tradeName="Roofing" />}
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.SOLAR}`}
            element={<TradePage tradeName="Solar" />}
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.PLUMBING}`}
            element={<TradePage tradeName="Plumbing" />}
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.ELECTRICAL}`}
            element={<TradePage tradeName="Electrical" />}
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.PAINTING}`}
            element={<TradePage tradeName="Painting" />}
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.PEST_CONTROL}`}
            element={<TradePage tradeName="Pest Control" />}
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.LANDSCAPING_AND_LAWN_CARE}`}
            element={<TradePage tradeName="Landscaping & Lawn Care" />}
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.POOL_CONSTRUCTION}`}
            element={<TradePage tradeName="Pool Construction" />}
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.CLEANING_SERVICES}`}
            element={<TradePage tradeName="Cleaning Services" />}
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.EPOXY_COATING}`}
            element={<TradePage tradeName="Epoxy Coating" />}
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.FLOORING}`}
            element={<TradePage tradeName="Flooring" />}
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.HOME_RENOVATION}`}
            element={<TradePage tradeName="Home Renovation" />}
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.GARAGE_DOOR_SERVICES}`}
            element={<TradePage tradeName="Garage Door Services" />}
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.HOLIDAY_LIGHT_INSTALLATION}`}
            element={<TradePage tradeName="Holiday Light Installation" />}
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.WINDOW_CLEANING}`}
            element={<TradePage tradeName="Window Cleaning" />}
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.HOME_SECURITY}`}
            element={<TradePage tradeName="Home Security" />}
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.APPLIANCE_REPAIR}`}
            element={<TradePage tradeName="Appliance Repair" />}
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.INTERIOR_DESIGN}`}
            element={<TradePage tradeName="Interior Design" />}
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.FURNITURE_ASSEMBLY}`}
            element={<TradePage tradeName="Furniture Assembly" />}
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.HOME_ORGANIZATION}`}
            element={<TradePage tradeName="Home Organization" />}
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.MASONRY}`}
            element={<TradePage tradeName="Masonry" />}
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.DECK_AND_PATIO_CONSTRUCTION}`}
            element={<TradePage tradeName="Deck and Patio Construction" />}
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.FENCING}`}
            element={<TradePage tradeName="Fencing" />}
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.WATERPROOFING}`}
            element={<TradePage tradeName="Waterproofing" />}
          />
          <Route path={ROUTES.PRIVACY_POLICY} element={<PrivacyPolicy />} />
          <Route path={ROUTES.TERMS_OF_USE} element={<TermsOfUse />} />

          <Route
            path={`${ROUTES.PAYMENT_SUCCESS}`}
            element={<PaymentSuccess />}
          />

          <Route path="*" element={<Navigate to={ROUTES.HOME} replace />} />
        </Routes>
      </div>
    );
  } else {
    return (
      <Routes>
        <Route path={ROUTES.HOME} element={<FormView />} />
        <Route path="*" element={<Navigate to={ROUTES.HOME} replace />} />
      </Routes>
    );
  }
}

export default App;
