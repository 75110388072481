import { useState } from "react";
import styles from "./formItemPreview.module.scss";
import trashIcon from "src/assets/trash.svg";
import editIcon from "src/assets/edit.svg";
import duplicateIcon from "src/assets/duplicate.svg";
import shareIcon from "src/assets/share.svg";
import qrCodeIcon from "src/assets/qrCode.svg";
import { RotatingLines } from "react-loader-spinner";
import ActionModal from "src/_elements/actionModal/actionModal";
import ROUTES from "src/_utils/routes/routes";
import { Link, useNavigate } from "react-router-dom";
import PublishFormModal from "src/pages/formView/publishFormModal/publishFormModal";
import ShareFormModal from "../shareFormModal/shareFormModal";
import QRCodeModal from "../QRCodeModal/QRCodeModal";
import { truncateText } from "src/_utils/truncateText";

const FormItemPreview: React.FC<any> = ({
  el,
  handleFormOpen,
  handleDeleteForm,
  handleDuplicateForm,
  handleUnpublishForm,
  getForms,
}: any) => {
  const navigate = useNavigate();

  const [isShowDeleteFormModal, setIsShowDeleteFormModal] = useState(false);
  const [isShowDuplicateFormModal, setIsShowDuplicateFormModal] =
    useState(false);
  const [isShowUnpublishFormModal, setIsShowUnpublishFormModal] =
    useState(false);
  const [isShowPublishModal, setIsShowPublishModal] = useState(false);
  const [isShowShareFormModal, setIsShowShareFormModal] = useState(false);
  const [isShowQRCodeFormModal, setIsShowQRCodeFormModal] = useState(false);
  const [selectedForm, setSelectedForm] = useState(null);

  const handleCloseDeleteModal = () => {
    setIsShowDeleteFormModal(false);
  };

  const handleCloseDuplicateModal = () => {
    setIsShowDuplicateFormModal(false);
  };

  const handleCloseShareFormModal = () => {
    setIsShowShareFormModal(false);
  };

  const handleCloseQRCodeFormModal = () => {
    setIsShowQRCodeFormModal(false);
  };

  const handleCloseUnpublishFormModal = () => {
    setIsShowUnpublishFormModal(false);
  };

  const handleDeleteButtonClick = (e: any) => {
    e.stopPropagation();
    e.preventDefault();

    setIsShowDeleteFormModal(true);
  };

  const handleEditButtonClick = (e: any, el: any) => {
    e.stopPropagation();
    e.preventDefault();

    navigate(`${ROUTES.EDIT_FORM}/${el.form?.id}`);
  };

  const handleDuplicateButtonClick = (e: any, el: any) => {
    e.stopPropagation();
    e.preventDefault();

    setIsShowDuplicateFormModal(true);
  };

  const handleShareButtonClick = (e: any, el: any) => {
    e.stopPropagation();
    e.preventDefault();

    setIsShowShareFormModal(true);
    setSelectedForm(el);
  };

  const handleQRCodeButtonClick = (e: any, el: any) => {
    e.stopPropagation();
    e.preventDefault();

    setIsShowQRCodeFormModal(true);
    setSelectedForm(el);
  };

  const handlePublishButtonClick = (e: any) => {
    e.stopPropagation();
    e.preventDefault();

    setIsShowPublishModal(true);
  };

  const handleUnpublishButtonClick = (e: any) => {
    e.stopPropagation();
    e.preventDefault();

    setIsShowUnpublishFormModal(true);
  };

  const FormItem: React.FC<any> = ({ handleFormOpenFoo }: any) => {
    return (
      <div
        className={`${styles.formButton} ${
          el.form.isPublished ? styles.formButtonPublushed : ""
        }`}
        onClick={() =>
          handleFormOpenFoo
            ? handleFormOpenFoo(el, el.formData.formsData.logo)
            : {}
        }
      >
        <button
          onClick={(e) => handleDeleteButtonClick(e)}
          className={styles.deleteFormButton}
        >
          <img src={trashIcon} alt="delete" />
        </button>
        <button
          onClick={(e) => handleEditButtonClick(e, el)}
          className={styles.editFormButton}
        >
          <img src={editIcon} alt="edit" />
        </button>
        <button
          onClick={(e) => handleDuplicateButtonClick(e, el)}
          className={styles.duplicateFormButton}
        >
          <img src={duplicateIcon} alt="duplicate" />
        </button>
        {el?.cname && el?.form?.isPublished && (
          <button
            onClick={(e) => handleShareButtonClick(e, el)}
            className={styles.shareFormButton}
          >
            <img src={shareIcon} alt="share" />
          </button>
        )}
        {el?.cname && el?.form?.isPublished && (
          <button
            onClick={(e) => handleQRCodeButtonClick(e, el)}
            className={styles.qrCodeButton}
          >
            <img height="20px" src={qrCodeIcon} alt="QR code" />
          </button>
        )}
        <div className={styles.formPreviewContent}>
          {el?.formData?.formsData.logo ? (
            <img
              width="80px"
              height="45px"
              src={el.formData.formsData.logo ?? ""}
              alt="logo"
            />
          ) : (
            <RotatingLines
              visible={true}
              width="30"
              strokeWidth="5"
              animationDuration="0.75"
              strokeColor="black"
            />
          )}
          <p>{truncateText(el.form.name, 20)}</p>
        </div>
        {!el.form.isPublished && (
          <button
            className={styles.publishActionBtn}
            onClick={handlePublishButtonClick}
          >
            Publish
          </button>
        )}
        {el.form.isPublished && (
          <button
            className={`${styles.publishActionBtn} ${styles.unpublishBtn}`}
            onClick={handleUnpublishButtonClick}
          >
            Unpublish
          </button>
        )}
      </div>
    );
  };

  return (
    <>
      {!el.form.isPublished ? (
        <Link to={`${ROUTES.FORMS}/${el.formData?.id}`}>
          <FormItem />
        </Link>
      ) : (
        <FormItem handleFormOpenFoo={handleFormOpen} />
      )}
      <ActionModal
        title={`Delete form "${el.form.name}"?`}
        subtitle="This will permanently remove the form."
        isShowModal={isShowDeleteFormModal}
        handleCloseModal={handleCloseDeleteModal}
        handleAction={(e: any) => handleDeleteForm(e, el)}
        actionButtonText="Delete"
      />
      <ActionModal
        title={`Duplicate form "${el.form.name}"?`}
        subtitle="This will make a copy of the form."
        isShowModal={isShowDuplicateFormModal}
        handleCloseModal={handleCloseDuplicateModal}
        handleAction={(e: any) => handleDuplicateForm(e, el)}
        actionButtonText="Copy"
      />
      <ActionModal
        title={`Unpublish form "${el.form.name}"?`}
        subtitle="This will unpublish your form, but you can publish it again later."
        isShowModal={isShowUnpublishFormModal}
        handleCloseModal={handleCloseUnpublishFormModal}
        handleAction={(e: any) => handleUnpublishForm(e, el)}
        actionButtonText="Unpublish"
      />
      <PublishFormModal
        isShowModal={isShowPublishModal}
        setIsShowModal={setIsShowPublishModal}
        formId={el.form?.id}
        getForms={getForms}
      />
      <ShareFormModal
        isShow={isShowShareFormModal}
        handleClose={handleCloseShareFormModal}
        selectedForm={selectedForm}
      />
      <QRCodeModal
        isShow={isShowQRCodeFormModal}
        handleClose={handleCloseQRCodeFormModal}
        selectedForm={selectedForm}
      />
    </>
  );
};

export default FormItemPreview;
