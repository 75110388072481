import { useNavigate } from "react-router-dom";
import styles from "./formTemplateItem.module.scss";
import editIcon from "src/assets/edit.svg";
import deleteIcon from "src/assets/trash.svg";
import ROUTES from "src/_utils/routes/routes";
import { deleteFormTemplateApi } from "src/_utils/api/api";
import ActionModal from "src/_elements/actionModal/actionModal";
import { useState } from "react";

const FormTemplateItem: React.FC<any> = ({ data }: any) => {
  const navigate = useNavigate();

  const [isShowDeleteFormTemplateModal, setIsShowDeleteFormTemplateModal] =
    useState(false);

  const handleEditFormTemplate = () => {
    navigate(`${ROUTES.EDIT_FORM_TEMPLTE}/${data.id}`);
  };

  const handleDeleteFormTemplate = () => {
    deleteFormTemplateApi(data.id).then(() => {
      setIsShowDeleteFormTemplateModal(false);
      window.location.reload();
    });
  };

  const handleCloseDeleteModal = () => {
    setIsShowDeleteFormTemplateModal(false);
  };

  return (
    <>
      <div className={styles.wrapper}>
        <button onClick={handleEditFormTemplate} className={styles.editBtn}>
          <img src={editIcon} alt="edit" />
        </button>
        <button
          onClick={() => setIsShowDeleteFormTemplateModal(true)}
          className={styles.deleteBtn}
        >
          <img src={deleteIcon} alt="delete" />
        </button>
        <img
          height="100px"
          width="100px"
          src={data.formsData[0].logo}
          alt="logo"
        />
        <p>{data.name}</p>
      </div>
      <ActionModal
        title={`Delete form template "${data.name}?"`}
        subtitle="This will permanently remove the form template."
        isShowModal={isShowDeleteFormTemplateModal}
        handleCloseModal={handleCloseDeleteModal}
        handleAction={handleDeleteFormTemplate}
        actionButtonText="Delete"
      />
    </>
  );
};

export default FormTemplateItem;
