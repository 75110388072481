import styles from "./benefits.module.scss";
import improvedLeadQuality from "src/assets/homePage/improvedLeadQuality.png";
import reducedSalesCycle from "src/assets/homePage/reducedSalesCycle.png";
import tailoredExperience from "src/assets/homePage/tailoredExperience.png";
import higherConversionRate from "src/assets/homePage/higherConversionRate.png";

const Benefits = () => {
  return (
    <div className={styles.wrapper}>
      <h3>Benefits of LeadQuiz</h3>
      <div className={styles.benefitsWrapper}>
        <div className={styles.benefitItem}>
          <img src={improvedLeadQuality} alt="Improved Lead Quality" />
          <p>Improved Lead Quality</p>
        </div>
        <div className={styles.benefitItem}>
          <img src={reducedSalesCycle} alt="Reduced Sales Cycle" />
          <p>Reduced Sales Cycle</p>
        </div>
        <div className={styles.benefitItem}>
          <img src={tailoredExperience} alt="Tailored Experience" />
          <p>Tailored Experience</p>
        </div>
        <div className={styles.benefitItem}>
          <img src={higherConversionRate} alt="Higher Conversion Rate" />
          <p>Higher Conversion Rate</p>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
