import FormTemplateItem from "./formTemplateItem/formTemplateItem";
import styles from "./formTemplates.module.scss";

const FormTemplates: React.FC<any> = ({
  handleAddNewFormTemplate,
  formTemplatesList,
}: any) => {
  return (
    <div className={styles.wrapper}>
      <button className={styles.button} onClick={handleAddNewFormTemplate}>
        <p>
          Add new
          <br />
          form template
        </p>
      </button>
      {formTemplatesList &&
        formTemplatesList.map((item: any, index: number) => (
          <FormTemplateItem key={index} index={index} data={item} />
        ))}
    </div>
  );
};

export default FormTemplates;
