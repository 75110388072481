import styles from "./calendars.module.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";

const Calendars: React.FC<any> = ({
  setStartDate,
  setEndDate,
  startDate,
  endDate,
}: any) => {
  const handleClearDateFilters = () => {
    setStartDate("");
    setEndDate("");
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.calendarItem}>
        <p>from:</p>
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(format(date as Date, "MM/dd/yyyy"))}
          dateFormat="MM/dd/yyyy"
          placeholderText="MM/DD/YYYY"
        />
      </div>
      <div className={styles.calendarItem}>
        <p>to:</p>
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(format(date as Date, "MM/dd/yyyy"))}
          dateFormat="MM/dd/yyyy"
          placeholderText="MM/DD/YYYY"
        />
      </div>
      <button onClick={handleClearDateFilters} className={styles.clearButton}>
        Clear date filters
      </button>
    </div>
  );
};

export default Calendars;
