import React from "react";
import styles from "./iconSelect.module.scss";
import IconsListModal from "./iconsListModal/iconsListModal";
import { IIconSelect } from "../interfaces/interfaces";
import imagePlaceholder from "src/assets/image.svg";

const IconSelect: React.FC<IIconSelect> = ({
  questionIcon,
  handleSelect,
  toggleDropdown,
  setIsShowIconsList,
  isShowIconsList,
  setCustomIcon,
  customIconPreview,
  setCustomIconPreview,
}: IIconSelect) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.innerWrapper}>
        <button className={styles.iconsButton} onClick={toggleDropdown}>
          <img
            src={
              customIconPreview !== ""
                ? customIconPreview
                : questionIcon
                ? questionIcon
                : imagePlaceholder
            }
            alt="icon"
            width="50px"
            height="50px"
          />
        </button>
        <IconsListModal
          isShowIconsList={isShowIconsList}
          setIsShowIconsList={setIsShowIconsList}
          handleSelect={handleSelect}
          setCustomIcon={setCustomIcon}
          setCustomIconPreview={setCustomIconPreview}
        />
      </div>
    </div>
  );
};

export default IconSelect;
