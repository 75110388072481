import React from "react";
import styles from "./HSWCYpopup.module.scss";
import crossIcon from "./../../../../../assets/cross.svg";

const HSWCYpopup: React.FC<any> = ({
  handleClosePopup,
  handleElementSelect,
}: any) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <p className={styles.title}>Select element type</p>
        <img src={crossIcon} alt="close" onClick={handleClosePopup} />
      </div>
      <ul>
        <li onClick={() => handleElementSelect("city")}>City</li>
        <li onClick={() => handleElementSelect("state")}>State</li>
        <li onClick={() => handleElementSelect("zipCode")}>Zip Code</li>
        <li onClick={() => handleElementSelect("address")}>Address</li>
        <li onClick={() => handleElementSelect("date")}>Date</li>
        <li onClick={() => handleElementSelect("time")}>Time</li>
        <li onClick={() => handleElementSelect("text")}>Input</li>
        <li onClick={() => handleElementSelect("textarea")}>Text area</li>
      </ul>
    </div>
  );
};

export default HSWCYpopup;
