import styles from "./top.module.scss";
import devices from "src/assets/homePage/devices.png";
import { useDispatch } from "react-redux";
import {
  setIsShowPricingModalAC,
  setIsShowRegisterModalAC,
} from "src/store/actions";

const Top = () => {
  const dispatch = useDispatch();

  const token = localStorage.getItem("token");

  return (
    <div className={styles.wrapper}>
      <div>
        <h1>Start generating quality leads.</h1>
        <p>
          Replace your Facebook forms, and confusing websites with an
          interactive form. Allowing your business to increase lead quality and
          convert more clients.
        </p>

        <button
          onClick={
            token
              ? () => dispatch(setIsShowPricingModalAC(true))
              : () => dispatch(setIsShowRegisterModalAC(true))
          }
        >
          Get started today
        </button>
      </div>
      <div className={styles.imgWrap}>
        <img src={devices} alt="devices" />
      </div>
    </div>
  );
};

export default Top;
