import { useEffect, useState } from "react";
import styles from "./howToMakeYourQuiz.module.scss";
import sliderImage1 from "src/assets/homePage/slider1.png";
import sliderImage2 from "src/assets/homePage/slider2.png";
import sliderImage3 from "src/assets/homePage/slider3.png";

const HowToMakeYourQuiz = () => {
  const [currentSliderStep, setCurrentSliderStep] = useState(1);
  const [currentSliderImage, setCurrentSliderImage] = useState(sliderImage1);

  useEffect(() => {
    switch (currentSliderStep) {
      case 1:
        setCurrentSliderImage(sliderImage1);
        break;
      case 2:
        setCurrentSliderImage(sliderImage2);
        break;
      case 3:
        setCurrentSliderImage(sliderImage3);
        break;
    }
  }, [currentSliderStep]);

  return (
    <div className={styles.wrapper}>
      <h4>How to make your quiz</h4>
      <div className={styles.sliderWrapper}>
        <img
          src={currentSliderImage}
          alt={`form builder ${currentSliderStep} step`}
        />
        <ul>
          <li
            className={currentSliderStep === 1 ? styles.selectedList : ""}
            onClick={() => setCurrentSliderStep(1)}
          >
            <div
              className={`${styles.listNumber} ${
                currentSliderStep === 1 ? styles.selectedListNumber : ""
              }`}
            >
              1
            </div>
            <div className={styles.listText}>
              <p className={styles.listTitle}>Upload your logo</p>
              <p className={styles.listSubtitle}>
                Simply upload your companies logo.
              </p>
            </div>
          </li>
          <li
            className={currentSliderStep === 2 ? styles.selectedList : ""}
            onClick={() => setCurrentSliderStep(2)}
          >
            <div
              className={`${styles.listNumber} ${
                currentSliderStep === 2 ? styles.selectedListNumber : ""
              }`}
            >
              2
            </div>
            <div className={styles.listText}>
              <p className={styles.listTitle}>Enter your requirements</p>
              <p className={styles.listSubtitle}>
                Input the questions you want to ask your prospects. We recommend
                3-5 questions.
              </p>
            </div>
          </li>
          <li
            className={currentSliderStep === 3 ? styles.selectedList : ""}
            onClick={() => setCurrentSliderStep(3)}
          >
            <div
              className={`${styles.listNumber} ${
                currentSliderStep === 3 ? styles.selectedListNumber : ""
              }`}
            >
              3
            </div>
            <div className={styles.listText}>
              <p className={styles.listTitle}>Publish your LeadQuiz</p>
              <p className={styles.listSubtitle}>
                Publish your LeadQuiz with your custom domain. You can now use
                LeadQuiz for Facebook Advertising, Google Ads, QR Codes, and so
                much more!
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default HowToMakeYourQuiz;
