import React, { useEffect, useState, useMemo } from "react";
import styles from "./conditionsModal.module.scss";
import Modal from "src/_elements/modal/modal";
import { useDispatch, useSelector } from "react-redux";
import { setConditionalAnswerOptionsForQuestionAC } from "src/store/actions";
import crossIcon from "src/assets/cross.svg";

const ConditionsModal: React.FC<any> = ({
  showConditionsModal,
  setShowConditionsModal,
  questionId,
  selectedQuestion,
}: any) => {
  const dispatch = useDispatch();
  const questionsList = useSelector((state: any) => state.questionsList);

  const [conditionalAnswerOptions, setConditionalAnswerOptions] = useState<
    { questionId: number; answerOptionId: string }[]
  >([]);
  const [selects, setSelects] = useState<number[]>([]);
  const [isHide, setIsHide] = useState<boolean>(false);

  const answerOptionsList = useMemo(() => {
    return questionsList.filter(
      (el: any) => el.id < questionId && el.answerOptions
    );
  }, [questionsList, questionId]);

  useEffect(() => {
    const currentQuestion = questionsList[questionId];

    if (currentQuestion?.conditionalAnswerOptions?.value?.length) {
      setConditionalAnswerOptions(
        currentQuestion.conditionalAnswerOptions.value
      );
      setSelects(
        new Array(currentQuestion.conditionalAnswerOptions.value.length).fill(0)
      );
      setIsHide(currentQuestion.conditionalAnswerOptions.isHide || false);
    } else {
      setConditionalAnswerOptions([]);
      setSelects([]);
      setIsHide(false);
    }
  }, [questionsList, questionId]);

  const handleCloseModal = () => {
    setShowConditionsModal(false);
  };

  const handleSave = () => {
    dispatch(
      setConditionalAnswerOptionsForQuestionAC(
        questionId,
        conditionalAnswerOptions,
        isHide
      )
    );
    handleCloseModal();
  };

  const handleAddSelect = () => {
    setSelects([...selects, selects.length]);
    setConditionalAnswerOptions([
      ...conditionalAnswerOptions,
      { questionId: answerOptionsList[0]?.id, answerOptionId: "" },
    ]);
  };

  const handleRemoveSelect = (index: number) => {
    const updatedSelects = selects.filter((_, i) => i !== index);
    const updatedOptions = conditionalAnswerOptions.filter(
      (_, i) => i !== index
    );
    setSelects(updatedSelects);
    setConditionalAnswerOptions(updatedOptions);
  };

  const handleQuestionChange = (index: number, value: number) => {
    const updatedOptions = [...conditionalAnswerOptions];
    updatedOptions[index].questionId = value;
    updatedOptions[index].answerOptionId = "";
    setConditionalAnswerOptions(updatedOptions);
  };

  const handleOptionChange = (index: number, value: string) => {
    const updatedOptions = [...conditionalAnswerOptions];
    updatedOptions[index].answerOptionId = value;
    setConditionalAnswerOptions(updatedOptions);
  };

  const getAllAnswerOptionsForQuestion = (questionId: number) => {
    const question = questionsList.find((q: any) => q.id === questionId);
    return question ? question.answerOptions : [];
  };

  return (
    <Modal
      className={styles.wrapper}
      isShow={showConditionsModal}
      onClose={handleCloseModal}
    >
      <div className={styles.content}>
        <p className={styles.title}>
          Edit logic for "{selectedQuestion}" question
        </p>
        <div className={styles.selectWrap}>
          <select
            value={isHide ? "hide" : "show"}
            onChange={(e) => setIsHide(e.target.value === "hide")}
            className={styles.select}
          >
            <option value="show">Show</option>
            <option value="hide">Hide</option>
          </select>
          <p>this step only when...</p>
        </div>

        {selects.map((_, index) => (
          <div className={styles.elementsWrap} key={index}>
            {index !== 0 && (
              <div className={styles.dividerWrap}>
                <div className={styles.orLabel}>or</div>
                <div className={styles.divider} />
              </div>
            )}
            <div className={styles.conditions}>
              <p>has selected:</p>
              <select
                value={
                  conditionalAnswerOptions[index]?.questionId !== undefined
                    ? conditionalAnswerOptions[index]?.questionId
                    : ""
                }
                onChange={(e) =>
                  handleQuestionChange(index, Number(e.target.value))
                }
                className={styles.select}
              >
                <option value="">Select Question</option>
                {answerOptionsList
                  .filter((el: any) => !el.isMultipleSelect)
                  .map((el: any) => (
                    <option key={el.id} value={el.id}>
                      {el.question}
                    </option>
                  ))}
              </select>

              <select
                value={conditionalAnswerOptions[index]?.answerOptionId || ""}
                onChange={(e) => handleOptionChange(index, e.target.value)}
                className={styles.select}
                disabled={
                  conditionalAnswerOptions[index]?.questionId === undefined
                }
              >
                <option value="">Select Answer Option</option>
                {conditionalAnswerOptions[index]?.questionId !== undefined &&
                  getAllAnswerOptionsForQuestion(
                    conditionalAnswerOptions[index]?.questionId
                  )
                    .filter(
                      (el: any) =>
                        !questionsList.find(
                          (q: any) =>
                            q.id === conditionalAnswerOptions[index]?.questionId
                        )?.isMultipleSelect
                    )
                    .map((el: any) => (
                      <option key={el.id} value={el.id}>
                        {el.title}
                      </option>
                    ))}
              </select>

              <button
                className={styles.removeBtn}
                onClick={() => handleRemoveSelect(index)}
              >
                <img src={crossIcon} alt="delete" />
              </button>
            </div>
          </div>
        ))}

        <div className={styles.addBtnWrap}>
          <button onClick={handleAddSelect} className={styles.addBtn}>
            {selects.length > 0 ? "or" : "add"}
          </button>
        </div>

        <div className={styles.saveBtnWrap}>
          <button onClick={handleSave} className={styles.saveBtn}>
            Submit
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConditionsModal;
