import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getFormsApi, getUserAnswersForFormApi } from "src/_utils/api/api";
import ROUTES from "src/_utils/routes/routes";
import Header from "../homePage/header/header";
import Filter from "./filter/filter";
import LeadItem from "./leadItem/leadItem";
import LeadsHeader from "./leadsHeader/leadsHeader";
import styles from "./leadsPage.module.scss";
import Pagination from "./pagination/pagination";

const LeadsPage = () => {
  const token = localStorage.getItem("token");

  const navigate = useNavigate();

  const isUserPlanActive = useSelector((state: any) => state.isUserPlanActive);
  const isCurrentUserPlanLoading = useSelector(
    (state: any) => state.isCurrentUserPlanLoading
  );

  const pageSize = 5;

  const [leadsList, setLeadsList] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchUsernameText, setSearchUsernameText] = useState("");
  const [debouncedSearchText, setDebouncedSearchText] = useState("");
  const [formsArray, setFormsArray] = useState<any>([]);
  const [selectedForm, setSelectedForm] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    if (!token || !isCurrentUserPlanLoading) {
      if (!isUserPlanActive) {
        navigate(ROUTES.HOME);
      }
    }
  }, [token, isUserPlanActive, isCurrentUserPlanLoading]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedSearchText(searchUsernameText);
    }, 500);

    return () => clearTimeout(timerId);
  }, [searchUsernameText]);

  useEffect(() => {
    if (selectedForm) {
      const payload = {
        formId: selectedForm,
        pageSize: pageSize,
        page: currentPage,
        searchUsernameText: searchUsernameText,
        startDate: startDate
          ? new Date(startDate as any).toISOString().split("T")[0] +
            "T00:00:00.000Z"
          : null,
        endDate: endDate
          ? new Date(endDate as any).toISOString().split("T")[0] +
            "T23:00:00.000Z"
          : null,
      };

      getUserAnswersForFormApi(payload).then((res) => {
        setLeadsList(res.data.data.formsData);
        setTotalPages(Math.ceil(res.data.data.total / pageSize));
      });
    }
  }, [currentPage, debouncedSearchText, selectedForm, startDate, endDate]);

  useEffect(() => {
    token &&
      getFormsApi().then((res) => {
        setFormsArray(res.data.data);
        setSelectedForm(res.data.data[0]?.form.id);
      });
  }, [token]);

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const leadsListArray =
    leadsList &&
    leadsList.map((el: any, index: number) => {
      const formattedDate = new Date(el.createdAt).toLocaleDateString("en-US");
      return (
        <LeadItem
          key={el.id}
          formattedDate={formattedDate}
          username={el.username}
          questionsAndAnswers={leadsList[index]}
          formData={
            formsArray.find(
              (f: any) => f?.form?.id && f?.form?.id === selectedForm
            )?.formData?.formsData
          }
        />
      );
    });

  const handleSearch = (e: any) => {
    setSearchUsernameText(e.target.value);
  };

  const handleClearSearchInput = () => {
    setSearchUsernameText("");
  };

  const handleFormChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedForm(event.target.value);
  };

  const leadsListForDownload = leadsList.map((item: any) => ({
    username: item.username,
    createdAt: new Date(item.createdAt).toLocaleDateString("en-US"),
    ...item.data,
  }));

  return (
    <div className={styles.outerWrapper}>
      <Helmet>
        <title>LeadQuiz | Leads</title>
      </Helmet>
      <Header />
      {!isCurrentUserPlanLoading && (
        <div className={styles.wrapper}>
          <div className={styles.elementWrapper}>
            <Filter
              selectedForm={selectedForm}
              handleFormChange={handleFormChange}
              formsArray={formsArray}
              handleSearch={handleSearch}
              handleClearSearchInput={handleClearSearchInput}
              searchUsernameText={searchUsernameText}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              startDate={startDate}
              endDate={endDate}
              leadsListForDownload={leadsListForDownload}
            />
          </div>
          <div className={styles.elementWrapper}>
            <div className={styles.formWrapper}>
              <LeadsHeader />
              {leadsListArray}
            </div>
          </div>
          <div className={styles.paginationWrapper}>
            <Pagination
              handlePageChange={handlePageChange}
              currentPage={currentPage}
              totalPages={totalPages}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default LeadsPage;
