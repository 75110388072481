import React, { useState, useEffect } from "react";
import AddElementButton from "src/_elements/addElementButton/addElementButton";
import FormInput from "src/_elements/formInput/formInput";
import styles from "./chooseQuestions.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { setContinueButtonDisabledAC, setQuestionsAC } from "src/store/actions";
import { IQuestion } from "../../interfaces/interfaces";
import trashIcon from "src/assets/trash.svg";
import dragIcon from "src/assets/drag.svg"

const ChooseQuestions = () => {
  const dispatch = useDispatch();
  const questionsList = useSelector((state: any) => state.questionsList);
  const [draggedIndex, setDraggedIndex] = useState<number | null>(null);

  useEffect(() => {
    dispatch(setContinueButtonDisabledAC(false));

    questionsList.forEach((el: any) => {
      if (el.question === "") {
        dispatch(setContinueButtonDisabledAC(true));
      }
    });
  }, [questionsList]);

  const addQuestion = () => {
    const newQuestion = {
      id: questionsList.length,
      question: "",
      answerOptions: null,
    };
    dispatch(setQuestionsAC([...questionsList, newQuestion]));
  };

  const handleQuestionChange = (index: number, value: string) => {
    const newQuestions = [...questionsList];
    newQuestions[index].question = value;
    dispatch(setQuestionsAC(newQuestions));
  };

  const removeQuestion = (index: number) => {
    const newQuestions = questionsList.filter((_: any, i: any) => i !== index);
    dispatch(setQuestionsAC(newQuestions));
  };

  const handleDragStart = (index: number) => {
    setDraggedIndex(index);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (index: number) => {
    if (draggedIndex === null || draggedIndex === index) return;

    const updatedQuestions = [...questionsList];

    const [draggedItem] = updatedQuestions.splice(draggedIndex, 1);
    updatedQuestions.splice(index, 0, draggedItem);

    updatedQuestions.forEach((item, idx) => {
      item.id = idx;
    });

    dispatch(setQuestionsAC(updatedQuestions));

    setDraggedIndex(null);
  };

  const handleDragEnd = () => {
    setDraggedIndex(null);
  };

  return (
    <div className={styles.outerWrapper}>
      <div className={styles.wrapper}>
        <h2>Enter questions</h2>
        <div className={styles.content}>
          {questionsList.map((el: IQuestion, index: number) => (
            <div
              key={el.id}
              className={styles.questionWrapper}
              draggable
              onDragStart={() => handleDragStart(index)}
              onDragOver={(e) => handleDragOver(e)}
              onDrop={() => handleDrop(index)}
              onDragEnd={handleDragEnd}
            >
              <div className={styles.questionInnerWrap}>
                <img className={styles.drag} src={dragIcon} alt="drag" />
                <FormInput
                  placeholder={`Question ${index + 1}`}
                  value={el.question}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleQuestionChange(index, e.target.value)
                  }
                />
                <button
                  onClick={() => removeQuestion(index)}
                  className={styles.removeQuestionBtn}
                >
                  <img src={trashIcon} alt="delete" />
                </button>
              </div>
            </div>
          ))}
          <div className={styles.addQuestionBtn}>
            <AddElementButton onClick={addQuestion}>
              + Add question
            </AddElementButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseQuestions;
