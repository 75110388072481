import { useEffect, useState } from "react";
import styles from "./publishFormModal.module.scss";
import Modal from "src/_elements/modal/modal";
import {
  getBusinessProfileSettingsApi,
  publishFormApi,
} from "src/_utils/api/api";
import { RotatingLines } from "react-loader-spinner";
import { Link, useNavigate } from "react-router-dom";
import ROUTES from "src/_utils/routes/routes";

const PublishFormModal: React.FC<any> = ({
  isShowModal,
  setIsShowModal,
  formId,
  getForms,
}) => {
  const navigate = useNavigate();

  const PLAN_LIMIT_ERROR_MESSAGE = "ERROR_CUSTOM_SUBDOMAINS_LIMIT_EXCEED";
  const ERROR_SUBDOMAIN_NAME_IS_ALREADY_TAKEN =
    "ERROR_SUBDOMAIN_NAME_IS_ALREADY_TAKEN";

  const [subdomainName, setSubdomainName] = useState("");
  const [isFormSaved, setIsFormSaved] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [businessProfileData, setBusinessProfileData] = useState<any>([]);
  const [selectedBusinessProfileId, setSelectedBusinessProfileId] =
    useState("");

  const handleSubdomainChange = (value: string) => {
    const filteredValue = value
      .toLowerCase()
      .replace(/[^a-z0-9-]/g, "")
      .replace(/ /g, "");

    setSubdomainName(filteredValue);
  };

  const handlePublishForm = () => {
    if (subdomainName === "www") {
      setErrorMessage("Invalid subdomain name");
    } else if (subdomainName !== "") {
      setIsLoading(true);

      const payload = {
        subdomainName: subdomainName,
        formId: formId,
        businessProfileId: selectedBusinessProfileId,
      };

      publishFormApi(payload)
        .then(() => {
          setIsFormSaved(true);
          setIsLoading(false);
          getForms ? getForms() : navigate(ROUTES.FORMS);
        })
        .catch((err) => {
          err.response.data.error.message === PLAN_LIMIT_ERROR_MESSAGE &&
            setErrorMessage(
              "You have reached your form creation limit in your current plan. You can purchase additional submissions or upgrade to a different plan."
            );

          err.response.data.error.message ===
            ERROR_SUBDOMAIN_NAME_IS_ALREADY_TAKEN &&
            setErrorMessage("Subdomain already taken");
          setIsLoading(false);
        });
    } else {
      setErrorMessage("Please enter a name for the subdomain");
    }
  };

  const handleCloseModal = () => {
    setIsShowModal(false);
    setSubdomainName("");
    setIsFormSaved(false);
    setErrorMessage("");
    setIsLoading(false);
  };

  useEffect(() => {
    if (isShowModal) {
      getBusinessProfileSettingsApi().then((res) => {
        setBusinessProfileData(res.data.data);
        setSelectedBusinessProfileId(res.data.data[0]?.id);
      });
    }
  }, [isShowModal]);

  return (
    <Modal
      className={styles.wrapper}
      isShow={isShowModal}
      onClose={handleCloseModal}
    >
      {businessProfileData && businessProfileData[0] ? (
        !isFormSaved ? (
          <div className={styles.wrapper}>
            <p className={styles.title}>Publish form?</p>
            <p>Enter a name for your form's subdomain</p>
            <div className={styles.inputWrapper}>
              <input
                value={subdomainName}
                onChange={(e) => handleSubdomainChange(e.target.value)}
                type="text"
              />
              <p>.leadquiz.com</p>
            </div>
            <div className={styles.businessProfileSelectWrap}>
              <p>Business profile:</p>
              <select
                value={selectedBusinessProfileId}
                onChange={(e) => setSelectedBusinessProfileId(e.target.value)}
                className={styles.select}
              >
                {businessProfileData.map((option: any) => (
                  <option key={option.id} value={option.id}>
                    {option.businessName}
                  </option>
                ))}
              </select>
            </div>
            <div className={styles.btnWrap}>
              {errorMessage && (
                <p className={styles.errorMessage}>{errorMessage}</p>
              )}
              <button onClick={handlePublishForm}>
                {!isLoading ? (
                  "Publish"
                ) : (
                  <RotatingLines
                    visible={true}
                    width="24"
                    strokeWidth="5"
                    animationDuration="0.75"
                    strokeColor="white"
                  />
                )}
              </button>
            </div>
          </div>
        ) : (
          <div className={styles.publishSuccess}>
            <p className={styles.titleSuccess}>
              The form has been published successfully
            </p>
            <p className={styles.description}>
              The form will be available in approximately 5 minutes at this
              address:{" "}
              <a
                href={`https://${subdomainName}.${process.env.REACT_APP_DOMAIN}`}
                target="_blank"
                rel="noopener noreferrer"
              >{`https://${subdomainName}.${process.env.REACT_APP_DOMAIN}`}</a>
              <br />
              You can close this window
            </p>
            <button onClick={handleCloseModal}>Ok</button>
          </div>
        )
      ) : (
        <div className={styles.wrapper}>
          <p className={styles.errorTitle}>
            Before publishing the form, please fill in your{" "}
            <Link className={styles.link} to={ROUTES.SETTINGS}>
              business profile details.
            </Link>
          </p>
        </div>
      )}
    </Modal>
  );
};

export default PublishFormModal;
