import React from "react";
import styles from "./addElementButton.module.scss";
import { IAddElementButton } from "../interfaces/interfaces";

const AddElementButton: React.FC<IAddElementButton> = ({
  onClick,
  children,
}: IAddElementButton) => {
  return (
    <div onClick={onClick} className={styles.wrapper}>
      {children}
    </div>
  );
};

export default AddElementButton;
