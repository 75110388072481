export const ArrowIcon = () => (
<svg width="20px" height="20px" viewBox="0 -4.5 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
        <g id="Dribbble-Light-Preview" transform="translate(-140.000000, -6683.000000)">
            <g id="icons" transform="translate(56.000000, 160.000000)">
                <path d="M84,6532.61035 L85.4053672,6534 L94.0131154,6525.73862 L94.9311945,6526.61986 L94.9261501,6526.61502 L102.573446,6533.95545 L104,6532.58614 C101.8864,6530.55736 95.9854722,6524.89321 94.0131154,6523 C92.5472155,6524.40611 93.9757869,6523.03486 84,6532.61035" id="arrow_up-[#340]">
                </path>
            </g>
        </g>
    </g>
</svg>
);

