import { ILeadsSearch } from "../interfaces/interfaces";
import styles from "./search.module.scss";
import searchIcon from "./../../../assets/leadsPage/search.svg";
import crossIcon from "./../../../assets/cross.svg";

const Search: React.FC<ILeadsSearch> = ({
  handleSearch,
  handleClearSearchInput,
  value,
}: ILeadsSearch) => {
  return (
    <div className={styles.wrapper}>
      <img className={styles.searchIcon} src={searchIcon} alt="search" />
      <input type="text" value={value} onChange={(e) => handleSearch(e)} />
      <button onClick={handleClearSearchInput} className={styles.clearInputBtn}>
        <img src={crossIcon} alt="clear" />
      </button>
    </div>
  );
};

export default Search;
