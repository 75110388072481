import Modal from "src/_elements/modal/modal";
import styles from "./QRCodeModal.module.scss";
import { useEffect, useState } from "react";
import { deleteFormQRCodeApi, getFormQRCodeApi } from "src/_utils/api/api";
import { RotatingLines } from "react-loader-spinner";

const QRCodeModal: React.FC<any> = ({
  isShow,
  handleClose,
  selectedForm,
}: any) => {
  const [qrCodeUrl, setQRCodeUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (selectedForm?.cname.id) {
      getFormQRCodeApi(selectedForm?.cname?.id).then((res) => {
        setQRCodeUrl(res.data.data.url);
        setIsLoading(false);
      });
    }
  }, [selectedForm]);

  const handleDeleteQRCode = () => {
    deleteFormQRCodeApi(selectedForm?.cname?.id).then(() => {
      handleClose();
    });
  };

  return (
    <Modal isShow={isShow} onClose={handleClose}>
      <div className={styles.content}>
        <p className={styles.title}>
          You can share your published form via QR code
        </p>
        <div className={styles.qrCodeWrapper}>
          {!isLoading ? (
            <img height="200px" width="200px" src={qrCodeUrl} alt="qrCode" />
          ) : (
            <RotatingLines
              visible={true}
              width="30"
              strokeWidth="5"
              animationDuration="0.75"
              strokeColor="black"
            />
          )}
        </div>
        <div className={styles.btnWrap}>
          <button onClick={handleDeleteQRCode} className={styles.btn}>
            Delete QR code
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default QRCodeModal;
