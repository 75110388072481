import React from "react";
import styles from "./pagination.module.scss";
import { IPagination } from "src/_elements/interfaces/interfaces";

const Pagination: React.FC<IPagination> = ({
  totalCount,
  currentPage,
  onPageChange,
  pageSize = 10,
  siblingCount = 1,
}: IPagination) => {
  const totalPages = Math.ceil(totalCount / pageSize);

  const range = (start: number, end: number) => {
    let length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
  };

  const paginationRange = () => {
    const totalPageNumbers = siblingCount + 5;

    if (totalPages <= totalPageNumbers) {
      return range(1, totalPages);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPages);

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPages - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPages;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * siblingCount;
      let leftRange = range(1, leftItemCount);

      return [...leftRange, "...", totalPages];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 + 2 * siblingCount;
      let rightRange = range(totalPages - rightItemCount + 1, totalPages);

      return [firstPageIndex, "...", ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, "...", ...middleRange, "...", lastPageIndex];
    }
  };

  const paginationRangeArray = paginationRange();

  if (totalPages === 1) return null;

  return (
    <ul className={styles.pagination}>
      <li
        className={`${styles.paginationItem} ${
          currentPage === 1 ? styles.disabled : ""
        }`}
        onClick={() =>
          onPageChange(currentPage === 1 ? currentPage : currentPage - 1)
        }
      >
        &laquo;
      </li>
      {paginationRangeArray &&
        paginationRangeArray.map((pageNumber, idx) => {
          if (pageNumber === "...") {
            return (
              <li
                key={idx}
                className={`${styles.paginationItem} ${styles.dots}`}
              >
                &#8230;
              </li>
            );
          }

          return (
            <li
              key={idx}
              className={`${styles.paginationItem} ${
                currentPage === pageNumber ? styles.selected : ""
              }`}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </li>
          );
        })}
      <li
        className={`${styles.paginationItem} ${
          currentPage === totalPages ? styles.disabled : ""
        }`}
        onClick={() =>
          onPageChange(
            currentPage === totalPages ? currentPage : currentPage + 1
          )
        }
      >
        &raquo;
      </li>
    </ul>
  );
};

export default Pagination;
