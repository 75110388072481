import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { activateUserApi } from "src/_utils/api/api";
import { IActivateUserPayload } from "src/_utils/interfaces/interfaces";
import ROUTES from "src/_utils/routes/routes";
import { setIsShowPricingModalAC } from "src/store/actions";

const ActivateUserPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      navigate(ROUTES.HOME);
      dispatch(setIsShowPricingModalAC(true));
    }
  }, [token]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");

    if (token) {
      localStorage.setItem("token", token);

      const payload: IActivateUserPayload = {
        token: token,
      };

      activateUserApi(payload)
        .then(() => {
          navigate(ROUTES.HOME);
          dispatch(setIsShowPricingModalAC(true));
        })
        .catch((err) => console.log(err));
    }
  }, [location]);

  return <div></div>;
};

export default ActivateUserPage;
